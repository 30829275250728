import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from './avatar';
import { IdCell } from './cards/id-cell-card.component';
import {
  CleanConversation,
  CleanMessage,
  CleanSupplier,
  CleanUser,
} from '../../export-types/cleaned-types';
import { useIntersection } from '@mantine/hooks';
import useSWRInfinite from 'swr/infinite';
import { api, fetcher, FetcherConfig } from '../../services/api';
import { MediaInputValue } from './media-input';
import { Controller, useForm } from 'react-hook-form';
import { uploadMediaInputFiles } from '../../services/file-service';
import { AlertService } from '../../services/alert.service';
import { MessageCard } from './message-card';
import { useProfile } from '../../api/profile';
import { ChatMediaInput } from './chat-media-input';
import _ from 'lodash';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { WhisperSpeaker } from '../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import {
  useConversation,
  useConversationNewMessages,
} from '../../api/conversation';
import EmojiPicker from 'emoji-picker-react';
import { formatCalendar } from '../../utils/date-value';
import classNames from 'classnames';
import { mutate as globalMutate } from 'swr';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConversationStatus } from '../screens/conversations/conversation-item';

type ConversationWindowProps = {
  internalId: number;
  id: string;
  type: 'delivery' | 'purchase' | 'parcel';
  tab: ConversationTab;
  supplier?: CleanSupplier;
  client?: CleanUser;
};

export type ConversationTab = 'supplier' | 'client';

type SendForm = {
  message: string;
  attachments: MediaInputValue[];
};

export const ConversationWindow = (props: ConversationWindowProps) => {
  const intl = useIntl();
  const [enableClipboardPasteHandlerChat, setEnableClipboardPasteHandlerChat] =
    useState(false);
  const getKey = (
    pageIndex: number,
    previousPageData: Array<CleanMessage>,
  ): FetcherConfig | null => {
    if (previousPageData && !previousPageData.length) return null;
    return {
      params: {
        deliveryOrderId: props.type === 'delivery' ? props.id : undefined,
        purchaseOrderId: props.type !== 'delivery' ? props.id : undefined,
        type: props.tab,
        messageId: previousPageData
          ? previousPageData[previousPageData.length - 1].id
          : undefined,
      },
      url: '/conversation/messages',
    };
  };
  const { data: conversation } = useConversation(
    props.id,
    props.type,
    props.tab,
  );
  const { data, mutate, size, setSize, isLoading } = useSWRInfinite<
    CleanMessage[]
  >(getKey, fetcher);
  const isEmpty = data?.[0]?.length === 0;
  const PAGE_SIZE = 10;
  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < PAGE_SIZE);

  const newMessagesHandler = React.useCallback(
    (conversation: CleanConversation) => {
      if (props.type === 'delivery') {
        if (conversation.deliveryOrder?.id === props.id) {
          mutate();
        }
      } else {
        if (conversation.purchaseOrder?.id === props.id) {
          mutate();
        }
      }
    },
    [mutate, props],
  );

  useConversationNewMessages(newMessagesHandler, 'conversation');

  useEffect(() => {
    globalMutate('/conversation/unread');
  }, [data]);

  const timeMessages = React.useMemo(() => {
    return _.chain(data)
      .flatten()
      .groupBy((message) => {
        return formatCalendar(message.sentAt);
      })
      .map((value, key) => ({ day: key, messages: value }))
      .value();
  }, [data]);
  const containerRef = useRef<HTMLDivElement>(null);
  const { ref, entry } = useIntersection({
    root: containerRef.current,
    threshold: 1,
  });
  const lastMessage = React.useMemo(() => {
    if (!data?.length) {
      return undefined;
    }
    const messages = data[data.length - 1];
    return messages.length ? messages[messages.length - 1].id : undefined;
  }, [data]);
  const typeName = React.useMemo(() => {
    if (props.type === 'parcel') {
      return (
        <FormattedMessage
          defaultMessage="Посылка"
          id="conversationWindow.label.parcel"
          description="Заголовок"
        />
      );
    } else if (props.type === 'purchase') {
      return (
        <FormattedMessage
          defaultMessage="Заказ"
          id="conversationWindow.label.purchase"
          description="Заголовок"
        />
      );
    } else if (props.type === 'delivery') {
      return (
        <FormattedMessage
          defaultMessage="Доставка"
          id="conversationWindow.label.delivery"
          description="Заголовок"
        />
      );
    }
  }, [props.type]);

  React.useEffect(() => {
    if (entry?.isIntersecting && !isLoadingMore && !isReachingEnd) {
      setSize(size + 1);
    }
  }, [entry, isLoadingMore, isReachingEnd, setSize, size]);
  const { data: profile } = useProfile();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
    getValues,
    setValue,
  } = useForm<SendForm>({
    defaultValues: {
      message: '',
      attachments: [],
    },
  });
  const renderMenu =
    (): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = async (
        eventKey: string | undefined,
        event: React.SyntheticEvent,
      ) => {
        event.stopPropagation();
        onClose();
        let statuses = conversation?.statuses || [];
        const index = statuses.indexOf(eventKey as ConversationStatus);
        if (index !== -1) {
          statuses.splice(index, 1);
        } else {
          if (eventKey === 'archive') {
            statuses = ['archive'];
          } else {
            statuses.push(eventKey as ConversationStatus);
          }
        }
        await api.post(
          `/conversation/status`,
          {
            statuses,
          },
          {
            params: {
              deliveryOrderId: props.type === 'delivery' ? props.id : undefined,
              purchaseOrderId: props.type !== 'delivery' ? props.id : undefined,
              type: props.tab,
            },
          },
        );
        AlertService.success();
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'unread'}>
              <span className="ps-2">
                <FormattedMessage
                  defaultMessage={`${
                    conversation?.statuses?.some(
                      (status) => status === 'unread',
                    )
                      ? 'Убрать'
                      : 'Пометить'
                  } "Не прочитано"`}
                  id="conversationWindow.dropdownItem.unread"
                  description="Заголовок"
                />
              </span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'refund'}>
              <span className="ps-2">
                <FormattedMessage
                  defaultMessage={`${
                    conversation?.statuses?.some(
                      (status) => status === 'refund',
                    )
                      ? 'Убрать'
                      : 'Пометить'
                  } "Возврат"`}
                  id="conversationWindow.dropdownItem.refund"
                  description="Заголовок"
                />
              </span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'defectCheck'}>
              <span className="ps-2">
                <FormattedMessage
                  defaultMessage={`${
                    conversation?.statuses?.some(
                      (status) => status === 'defectCheck',
                    )
                      ? 'Убрать'
                      : 'Пометить'
                  } "Проверка на брак"`}
                  id="conversationWindow.dropdownItem.defectCheck"
                  description="Заголовок"
                />
              </span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'adminAssistance'}>
              <span className="ps-2">
                <FormattedMessage
                  defaultMessage={`${
                    conversation?.statuses?.some(
                      (status) => status === 'adminAssistance',
                    )
                      ? 'Убрать'
                      : 'Пометить'
                  } "Помощь Админа"`}
                  id="conversationWindow.dropdownItem.adminAssistance"
                  description="Заголовок"
                />
              </span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'delayed'}>
              <span className="ps-2">
                <FormattedMessage
                  defaultMessage={`${
                    conversation?.statuses?.some(
                      (status) => status === 'delayed',
                    )
                      ? 'Убрать'
                      : 'Пометить'
                  } Задержка"`}
                  id="conversationWindow.dropdownItem.delayed"
                  description="Заголовок"
                />
              </span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'archive'}>
              <span className="ps-2">
                <FormattedMessage
                  defaultMessage={`${
                    conversation?.statuses?.some(
                      (status) => status === 'archive',
                    )
                      ? 'Убрать'
                      : 'Пометить'
                  } "Архив"`}
                  id="conversationWindow.dropdownItem.archive"
                  description="Заголовок"
                />
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };
  const onSubmit = handleSubmit(async (data) => {
    const attachments = await uploadMediaInputFiles(data.attachments);
    if (attachments.length === 0 && !data.message) {
      return;
    }
    await api
      .post(
        `/conversation/messages`,
        {
          message: data.message,
          attachments: attachments.map((attachment) => attachment.file),
        },
        {
          params: {
            deliveryOrderId: props.type === 'delivery' ? props.id : undefined,
            purchaseOrderId: props.type !== 'delivery' ? props.id : undefined,
            type: props.tab,
          },
        },
      )
      .then(() => {
        reset();
        AlertService.success();
        mutate();
      });
  });

  const addEmoji = (emoji: string) => {
    const message = getValues('message');
    setValue('message', message + emoji);
  };
  const emojiPicker = (
    { onClose, left, top, className }: any,
    ref: React.Ref<any>,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <EmojiPicker
        onEmojiClick={(emojiData) => addEmoji(emojiData.emoji)}
        lazyLoadEmojis={true}
      />
    </Popover>
  );

  const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    if (
      event.key === 'Enter' &&
      !(event.shiftKey || event.altKey || event.ctrlKey || event.metaKey)
    ) {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <div
      className={classNames(
        props.tab === 'supplier' ? ' bg-gray-50' : 'bg-teal-100',
        'd-flex flex-column flex-grow-1 border border-gray rounded mt-2',
      )}
      style={{ maxHeight: '80vh' }}
    >
      <div className="d-flex flex-row  gap-1 justify-content-between align-items-center m-2  ">
        <div className="d-flex flex-row gap-4 justify-content-center">
          <div>
            <Avatar width={44} />
          </div>
          <div className="d-flex flex-column">
            <div>
              {typeName}{' '}
              <IdCell internalId={props.internalId} type={props.type} />
            </div>
            {props.tab === 'supplier' ? (
              <div className="color-gray-400 ">
                <FormattedMessage
                  defaultMessage="Поставщик {value}"
                  id="conversationWindow.title.supplier"
                  description="Заголовок "
                  values={{
                    value: props.supplier?.name,
                  }}
                />
              </div>
            ) : (
              <div className="color-gray-400 ">Клиент {props.client?.name}</div>
            )}
          </div>
        </div>
        <Whisper
          placement="leftStart"
          trigger="click"
          onClick={(e) => e.stopPropagation()}
          speaker={renderMenu()}
        >
          <IconButton
            appearance="subtle"
            icon={<i className="bi bi-three-dots" />}
          />
        </Whisper>
      </div>
      <div
        className="col-10 w-100 flex-grow-1 position-relative border-top border-gray"
        style={{ minHeight: '30vh' }}
      >
        <div
          ref={containerRef}
          className="position-absolute corner-position overflow-auto  d-flex flex-column-reverse  w-100  "
        >
          {timeMessages.map(({ day, messages }) => (
            <>
              {messages.map((message) => (
                <MessageCard
                  innerRef={lastMessage === message.id ? ref : undefined}
                  self={profile?.id === message.user.id}
                  message={message}
                  tab={props.tab}
                  onDelete={() => mutate()}
                  key={message.id}
                />
              ))}
              <div className="align-self-center">{day}</div>
            </>
          ))}
        </div>
      </div>
      <form
        onSubmit={onSubmit}
        className="d-flex flex-row gap-1 justify-content-between align-items-center border-top py-3 px-2 border-gray"
      >
        <div className="align-content-center">
          <Controller
            control={control}
            name="attachments"
            render={({ field }) => (
              <ChatMediaInput
                {...field}
                multiple={true}
                enableClipboardPasteHandlerChat={
                  enableClipboardPasteHandlerChat
                }
              />
            )}
          />
        </div>
        <div className="text-center">
          <Whisper placement="top" trigger="click" speaker={emojiPicker}>
            <button className="bg-transparent">
              <i className="bi bi-emoji-smile-fill"></i>
            </button>
          </Whisper>
        </div>
        <div className="h-100 w-100">
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <textarea
                {...field}
                className="form-control bg-gray h-100"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Написать сообщение...',
                  id: 'conversationWindow.placeholder.writeMessage',
                  description: 'Поле ввода',
                })}
                onFocus={() => setEnableClipboardPasteHandlerChat(true)}
                onBlur={() => setEnableClipboardPasteHandlerChat(false)}
                onKeyDown={handleKeyDown}
              />
            )}
          />
        </div>
        <div className="col-1 text-center">
          <button
            type="submit"
            className="bg-green-500 rounded-circle"
            disabled={isSubmitting}
          >
            {!isSubmitting ? (
              <i className="bi bi-arrow-up text-white" />
            ) : (
              <i className="bi bi-pro text-white" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
