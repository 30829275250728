import React from 'react';
import { usePurchaseOrderStatuses } from '../../../consts/db-values';
import { NavLink } from 'react-router-dom';

type StatusType =
  | 'waitingForPayment'
  | 'inWork'
  | 'onTheWayToStock'
  | 'receivedInStock'
  | 'receivedInRetailStock'
  | 'sentToRussia'
  | 'canceled'
  | 'frozen'
  | 'hiddenFrozen';

type StatusCellProps = {
  deliveryOrder?: {
    id: string;
    internalId: number;
  };
  status: StatusType;
  cancelReason?: string | null;
};

export const StatusCell: React.FC<StatusCellProps> = ({
  deliveryOrder,
  status,
  cancelReason,
}) => {
  const PURCHASE_ORDER_STATUSES = usePurchaseOrderStatuses();
  return deliveryOrder ? (
    <NavLink
      className={`purchase-order-status ${status} text-decoration-none`}
      to={`/deliveries/delivery-order/${deliveryOrder.id}`}
    >
      {PURCHASE_ORDER_STATUSES[status]}
      {` D${deliveryOrder.internalId}`}
    </NavLink>
  ) : (
    <div className={`purchase-order-status ${status}`}>
      {PURCHASE_ORDER_STATUSES[status]}
      {cancelReason && ` / ${cancelReason}`}
    </div>
  );
};
