import { useIntl } from 'react-intl';

export const ROLES = {
  admin: 'Админ',
  employee: 'Сотрудник',
  client: 'Клиент',
};

export const ACCESSES = {
  purchase: 'Поставщик: закупки',
  warehouse: 'Поставщик: склад',
  salesManager: 'Менеджер по продажам',
  supplierFinances: 'Поставщик: финансы',
  marketer: 'Маркетолог',
  transit: 'Склад: транзит',
  financier: 'Финансист',
  reader: 'Читатель',
  fulfillment: 'Склад: фулфилмент',
};

export const CURRENCIES = {
  usd: 'USD',
  cny: 'CNY',
  rub: 'RUB',
};

export const WALLETS = {
  sberbank: 'Сбербанк',
  alfabank: 'Альфабанк',
  tinkoff: 'Тинькофф',
  cash: 'Наличные (партнёр)',
  invoice: 'По счёту для юр. лица',
  alipay: 'AliPay',
  wechat: 'WeChat',
  'pay-keeper_card': 'Онлайн оплата картой',
  'pay-keeper_sbp': 'Оплата по СБП',
  cryptomus: 'Криптовалюта',
  'cash-company': 'Наличные (офис ET)',
  payselection: 'Онлайн оплата Payselection',
  sbp: 'Перевод СБП',
  wata: 'Онлайн оплата Wata',
  'wata-sbp': 'Онлайн оплата Wata СБП',
};

export function useOrderCategoriesList() {
  const intl = useIntl();

  return [
    {
      value: 'householdGoods',
      label: intl.formatMessage({
        defaultMessage: 'Большие Хоз. Товары',
        id: 'category.label.householdGoods',
        description: 'Список категорий товаров',
      }),
    },
    {
      value: 'smallHouseholdGoods',
      label: intl.formatMessage({
        defaultMessage: 'Маленькие Хоз. Товары',
        id: 'category.label.smallHouseholdGoods',
        description: 'Список категорий товаров',
      }),
    },
    {
      value: 'clothes',
      label: intl.formatMessage({
        defaultMessage: 'Одежда',
        id: 'category.label.clothes',
        description: 'Список категорий товаров',
      }),
    },
  ];
}

export function usePurchaseOrderStatuses() {
  const intl = useIntl();

  return {
    sentToRussia: intl.formatMessage({
      defaultMessage: 'Передан на доставку',
      id: 'purchaseStatus.label.transferredToShipping',
      description: 'Статус заказа на покупку',
    }),
    waitingForPayment: intl.formatMessage({
      defaultMessage: 'Ожидание оплаты',
      id: 'order.status-label.awaitingPayment',
      description: 'Статус заказа',
    }),
    inWork: intl.formatMessage({
      defaultMessage: 'В работе',
      id: 'purchaseStatuses.label.inProgress',
      description: 'Статус заказа на покупку',
    }),
    onTheWayToStock: intl.formatMessage({
      defaultMessage: 'В пути на склад',
      id: 'purchaseStatuses.label.inTransitToAWarehouseInChina',
      description: 'Статус заказа на покупку',
    }),
    receivedInStock: intl.formatMessage({
      defaultMessage: 'Готов к отправке в РФ',
      id: 'purchaseStatuses.label.readyForShipmentToTheRussianFederation',
      description: 'Статус заказа на покупку',
    }),
    receivedInRetailStock: intl.formatMessage({
      defaultMessage: 'Готов к отправке в РФ (Розница)',
      id: 'purchaseStatuses.label.ReadyShip',
      description: 'Статус заказа на покупку',
    }),
    canceled: intl.formatMessage({
      defaultMessage: 'Отменен',
      id: 'order.status-label.canceled',
      description: 'Статус заказа',
    }),
    frozen: intl.formatMessage({
      defaultMessage: 'Заморожен',
      id: 'purchaseStatuses.label.freezed',
      description: 'Статус заказа на покупку',
    }),
    hiddenFrozen: 'Скрытая заморозка',
  };
}

export function useDeliveryOrderStatuses() {
  const intl = useIntl();

  return {
    assembly: intl.formatMessage({
      defaultMessage: 'Комплектация',
      id: 'deliveryStatuses.label.onPicking',
      description: 'Статус заказа на доставку',
    }),
    assembled: intl.formatMessage({
      defaultMessage: 'Укомплектован',
      id: 'deliveryStatuses.label.Equipped',
      description: 'Статус заказа на доставку',
    }),
    sentToRussia: intl.formatMessage({
      defaultMessage: 'Отправлен на склад РФ',
      id: 'deliveryStatuses.label.shippedToTheRussianFederationWarehouse',
      description: 'Статус заказа на доставку',
    }),
    receivedInRussia: intl.formatMessage({
      defaultMessage: 'Получен на склад РФ',
      id: 'deliveryStatuses.label.receivedInRussia',
      description: 'Статус заказа на доставку',
    }),
    receivedInRetailRussia: intl.formatMessage({
      defaultMessage: 'Получен на склад РФ (Розница)',
      id: 'deliveryStatuses.label.receivedRussianWarehouse',
      description: 'Статус заказа на доставку',
    }),
    sentToRecipient: intl.formatMessage({
      defaultMessage: 'Отправлен получателю',
      id: 'deliveryStatuses.label.shippedToRecipient',
      description: 'Статус заказа на доставку',
    }),
    delivered: intl.formatMessage({
      defaultMessage: 'Доставлен',
      id: 'deliveryStatuses.label.deliveredt',
      description: 'Статус заказа на доставку',
    }),
  };
}

export function useDeliveryMethods() {
  const intl = useIntl();

  return {
    air: intl.formatMessage({
      defaultMessage: 'Авиа',
      id: 'deliveryMethods.label.air',
      description: 'Способ доставки',
    }),
    railway: intl.formatMessage({
      defaultMessage: 'Ж/Д',
      id: 'deliveryMethods.label.railway',
      description: 'Способ доставки',
    }),
    auto: intl.formatMessage({
      defaultMessage: 'Авто',
      id: 'deliveryMethods.label.truck',
      description: 'Способ доставки',
    }),
    air_direct: intl.formatMessage({
      defaultMessage: 'Прямой Авиа',
      id: 'deliveryMethods.label.directAir',
      description: 'Способ доставки',
    }),
  };
}

export function useOrderCategories() {
  const intl = useIntl();

  return {
    householdGoods: intl.formatMessage({
      defaultMessage: 'Большие Хоз. Товары',
      id: 'category.label.householdGoods',
      description: 'Список категорий товаров',
    }),
    smallHouseholdGoods: intl.formatMessage({
      defaultMessage: 'Маленькие Хоз. Товары',
      id: 'category.label.smallHouseholdGoods',
      description: 'Список категорий товаров',
    }),
    clothes: intl.formatMessage({
      defaultMessage: 'Одежда',
      id: 'category.label.clothes',
      description: 'Список категорий товаров',
    }),
  };
}

export const PROMOTION_TYPES = {
  commission: 'Комиссия на выкуп',
  bonus: 'Бонус',
};
