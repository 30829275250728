import { toast } from 'react-toastify';
import React from 'react';

export class AlertService {
  static error(error: React.ReactNode) {
    toast.error(error);
  }

  static success(message: React.ReactNode = 'Успех!') {
    toast.success(message);
  }

  static warning(message: React.ReactNode) {
    toast.warning(message);
  }
}
