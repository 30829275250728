import React from 'react';
import { useOutletContext } from 'react-router-dom';
import Pagination from 'rsuite/Pagination';
import MyLoader from '../../common/loader.component';
import { OutletContextParams } from './delivery-orders-screen';
import { usePaginationQs } from '../../../utils/usePagination';
import { useSearchParamsPaginationEffect } from '../purchase-order/useSearchParamsPaginationEffect';
import {
  CleanConversation,
  CleanTransportation,
} from '../../../export-types/cleaned-types';
import { useConversationNewMessages } from '../../../api/conversation';
import { TransportationTabItem } from './transportation-tab-item';
import { useTransportations } from '../../../api/transportations';

type TransportationsTabProps = {
  type?: 'standalone' | 'combined';
  status: 'created' | 'sent' | 'received';
};

export const TransportationsTab = (props: TransportationsTabProps) => {
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(15);

  useSearchParamsPaginationEffect(handleSetPage);
  const {
    debouncedInternalId,
    linked,
    expanded,
    collapsed,
    onChangeCollapsed,
    selectSupplier,
    direction,
  } = useOutletContext<OutletContextParams>();

  const sort = (() => {
    switch (props.status) {
      case 'created':
        return 'createdAt';
      case 'sent':
        return 'sentAt';
      case 'received':
        return 'receivedAt';
    }
  })();

  const { data, error, isLoading, mutate } = useTransportations({
    linked,
    types: props.type ? [props.type] : undefined,
    status: [props.status],
    take,
    skip,
    name: debouncedInternalId || undefined,
    supplierId: selectSupplier || undefined,
    direction,
    sort,
  });

  const newMessageHandler = React.useCallback(
    (updatedConversation: CleanConversation) => {
      if (
        data?.items.some((transportation) =>
          transportation.lines.some(
            (line) =>
              line.deliveryOrder.id === updatedConversation.deliveryOrder?.id,
          ),
        )
      ) {
        mutate();
      }
    },
    [data, mutate],
  );
  useConversationNewMessages(newMessageHandler, 'deliveries');

  const onToggle = (transportation: CleanTransportation) => {
    if (collapsed.includes(transportation.id)) {
      const newCollapsed = [...collapsed];
      newCollapsed.splice(newCollapsed.indexOf(transportation.id), 1);
      onChangeCollapsed(newCollapsed);
    } else {
      onChangeCollapsed([...collapsed, transportation.id]);
    }
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  const status = (() => {
    switch (props.status) {
      case 'created':
        return 'assembly';
      case 'sent':
        return 'sentToRussia';
      case 'received':
        return 'sentToRussia';
    }
  })();

  return (
    <>
      <div className="pb-4">
        {data.items.map((data) => (
          <TransportationTabItem
            key={data.id}
            data={data}
            onCollapsedChange={() => {
              onToggle(data);
            }}
            collapsed={
              (!expanded && collapsed.includes(data.id)) ||
              (expanded && !collapsed.includes(data.id))
            }
            status={status}
          />
        ))}
      </div>
      {data.total > take && (
        <div className="pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={data?.total}
            limitOptions={[15, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};
