import React, { useEffect, useState } from 'react';
import { Header } from '../../common/header.component';
import Dropdown from 'rsuite/Dropdown';
import { PurchaseOrderDeliveryTariffModal } from './purchase-order-delivery-tariff-modal.component';
import {
  PurchaseOrderService,
  usePurchaseOrderTariffs,
} from '../../../services/purchase-order.service';
import { AlertService } from '../../../services/alert.service';
import { ModalsService } from '../../../services/modals.service';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import { ParcelModal } from '../../common/parcel-modal';
import { Rbac, RbacSome } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutoSendParametersEditModal } from './auto-send-parameters-edit-modal';
import { api } from '../../../services/api';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { PurchaseOrderFreezeManyModal } from './purchase-order-freeze-many-modal';
import { ConfirmModal } from '../../common/confirm-modal';
import { PurchaseOrderHiddenFreezeManyModal } from './purchase-order-hidden-freeze-many-modal';

export type SelectedOrdersContextParams = {
  selectedOrders: Set<string>;
  setSelectedOrders: (orders: Set<string>) => void;
  isSelectAll: boolean;
  isUnselectAll: boolean;
};

export const SelectedOrdersContext =
  React.createContext<SelectedOrdersContextParams>({
    selectedOrders: new Set(),
    setSelectedOrders: () => {},
    isSelectAll: false,
    isUnselectAll: false,
  });
export const AdminOrdersScreen = () => {
  const intl = useIntl();
  const { mutate: purchaseOrderTariffsMutate } = usePurchaseOrderTariffs();
  const { mutate: globalMutate } = useGlobalMutate();
  const { pathname } = useLocation();
  const orderStatus = pathname.split('/')[3];

  const [selectedOrders, setSelectedOrders] = useState<Set<string>>(new Set());
  const location = useLocation();

  const [isSelectAll, selectAll] = useState(false);
  const [isUnselectAll, unselectAll] = useState(false);

  useEffect(() => {
    setSelectedOrders(new Set());
    unselectAll((prevState) => !prevState);
  }, [location]);

  return (
    <>
      <SelectedOrdersContext.Provider
        value={{
          selectedOrders: selectedOrders,
          setSelectedOrders: setSelectedOrders,
          isSelectAll: isSelectAll,
          isUnselectAll: isUnselectAll,
        }}
      >
        <Header>
          <div className="d-flex flex-row align-items-center">
            <h1>
              <FormattedMessage
                defaultMessage="Заказы"
                id="orders.label.orders"
                description="Заголовок страницы"
              />
            </h1>
            <RbacSome
              checks={[
                { object: RbacObject.Purchase, action: 'write:admin-create' },
              ]}
            >
              <div className="ps-4">
                <Dropdown
                  toggleClassName="btn btn-success"
                  placement="bottomEnd"
                  title={intl.formatMessage({
                    defaultMessage: 'Добавить',
                    id: 'orders.btn-label.add',
                    description: 'Кнопка',
                  })}
                >
                  <Rbac
                    object={RbacObject.PurchaseOrderDeliveryTariff}
                    action={'write'}
                  >
                    <Dropdown.Item
                      onSelect={() => {
                        ModalsService.createModal(
                          PurchaseOrderDeliveryTariffModal,
                          {
                            title: 'Тариф на доставку',
                          },
                        )
                          .then((tariff) =>
                            PurchaseOrderService.createTariff(tariff),
                          )
                          .then(() => {
                            purchaseOrderTariffsMutate();
                            AlertService.success();
                          });
                      }}
                    >
                      <i className="bi bi-truck" />
                      <span className="ps-2">Тариф на доставку</span>
                    </Dropdown.Item>
                  </Rbac>
                  <Rbac
                    object={
                      RbacObject.PurchaseOrderClientExpensesPurchaseMarkup
                    }
                    action={'write'}
                  >
                    <Dropdown.Item as={Link} to="commissions/create">
                      <i className="bi bi-percent" />
                      <span className="ps-2">Комиссия за выкуп</span>
                    </Dropdown.Item>
                  </Rbac>
                  <RbacSome
                    checks={[
                      {
                        object: RbacObject.Purchase,
                        action: 'write:admin-create',
                      },
                      {
                        object: RbacObject.Parcel,
                        action: 'write:admin-create',
                      },
                    ]}
                  >
                    <Dropdown.Item
                      onSelect={() => {
                        ModalsService.createModal(ParcelModal, {});
                      }}
                    >
                      <i className="bi bi-plus-circle-fill me-2" />
                      Создать заказ
                    </Dropdown.Item>
                  </RbacSome>
                  <Rbac object={RbacObject.AutoSendParameters} action={'write'}>
                    <Dropdown.Item
                      onSelect={() => {
                        ModalsService.createModal(AutoSendParametersEditModal)
                          .then((data) => {
                            return api.post(`/auto-send`, {
                              count: Number(data.count),
                              weight: data.weight,
                              volume: data.volume,
                              price: data.price,
                              deliveryMethod: data.deliveryMethod,
                              localDeliveryMethodId: data.localDeliveryMethodId,
                              supplierId: data.supplierId,
                            });
                          })
                          .then(() => {
                            globalMutate();
                            AlertService.success();
                          });
                      }}
                    >
                      <i className="bi bi-truck" />
                      <span className="ps-2">Авто-отправку</span>
                    </Dropdown.Item>
                  </Rbac>
                  <Rbac
                    object={RbacObject.ServiceChargeOption}
                    action={'write:admin'}
                  >
                    <Dropdown.Item
                      as={Link}
                      to="/purchases/service-charge-option/form"
                    >
                      <i className="bi bi-wallet2"></i>
                      <span className="ps-2">Доп.расходы за выкуп</span>
                    </Dropdown.Item>
                  </Rbac>
                </Dropdown>
              </div>

              <div className="ps-4">
                <Dropdown
                  toggleClassName="btn btn-success"
                  placement="bottomEnd"
                  disabled={
                    orderStatus !== 'in-work' &&
                    orderStatus !== 'frozen' &&
                    orderStatus !== 'hidden-frozen'
                  }
                  title={intl.formatMessage({
                    defaultMessage: 'Команды',
                    id: 'orders.btn-label.comands',
                    description: 'Кнопка',
                  })}
                >
                  {orderStatus === 'in-work' && !!selectedOrders.size && (
                    <Dropdown.Item
                      onSelect={() => {
                        ModalsService.createModal(
                          PurchaseOrderFreezeManyModal,
                          {
                            orderIds: Array.from(selectedOrders),
                          },
                        ).then(() => {
                          globalMutate();
                          AlertService.success();
                        });
                      }}
                    >
                      <span className="ps-2">Заморозить</span>
                    </Dropdown.Item>
                  )}
                  {orderStatus === 'in-work' && !!selectedOrders.size && (
                    <Dropdown.Item
                      onSelect={() => {
                        ModalsService.createModal(
                          PurchaseOrderHiddenFreezeManyModal,
                          {
                            orderIds: Array.from(selectedOrders),
                          },
                        ).then(() => {
                          globalMutate();
                          AlertService.success();
                        });
                      }}
                    >
                      <span className="ps-2">Скрытая заморозка</span>
                    </Dropdown.Item>
                  )}

                  {(orderStatus === 'frozen' ||
                    orderStatus === 'hidden-frozen') &&
                    !!selectedOrders.size && (
                      <Dropdown.Item
                        onSelect={() => {
                          ModalsService.createModal(ConfirmModal, {
                            title: 'Разморозка заказа',
                            buttonText: `Разморозить заказ`,
                          }).then(() => {
                            api
                              .post(`/purchase-order/unfreezeOrders`, {
                                ids: Array.from(selectedOrders),
                              })
                              .then(() => {
                                globalMutate();
                                AlertService.success();
                              });
                          });
                        }}
                      >
                        <span className="ps-2">Разморозить</span>
                      </Dropdown.Item>
                    )}
                  <Dropdown.Item
                    onSelect={() => {
                      selectAll((prevState) => !prevState);
                    }}
                  >
                    <span className="ps-2">Выделить все</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => {
                      unselectAll((prevState) => !prevState);
                    }}
                  >
                    <span className="ps-2">Убрать все</span>
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </RbacSome>
          </div>
        </Header>
        <div className="d-flex flex-column">
          <RbacSome
            checks={[
              { object: RbacObject.Purchase, action: 'read' },
              {
                object: RbacObject.PurchaseOrderDeliveryTariff,
                action: 'read',
              },
              {
                object: RbacObject.PurchaseOrderClientExpensesPurchaseMarkup,
                action: 'read',
              },
            ]}
          >
            <ul className="nav nav-pills mb-4 d-inline-flex align-self-start">
              <Rbac object={RbacObject.Purchase} action={'read'}>
                <li className="nav-item">
                  <NavLink
                    to="/purchases/orders"
                    className="nav-link text-decoration-none"
                  >
                    <FormattedMessage
                      defaultMessage="Выкупы"
                      id="orders.label.redemptions"
                      description="Надпись на вкладке"
                    />
                  </NavLink>
                </li>
              </Rbac>
              <Rbac
                object={RbacObject.PurchaseOrderDeliveryTariff}
                action={'write'}
              >
                <li className="nav-item">
                  <NavLink
                    to="/purchases/tariffs"
                    className="nav-link text-decoration-none"
                  >
                    <FormattedMessage
                      defaultMessage="Тарифы на доставку"
                      id="orders.label.deliveryRates"
                      description="Надпись на вкладке"
                    />
                  </NavLink>
                </li>
              </Rbac>
              <Rbac
                object={RbacObject.PurchaseOrderClientExpensesPurchaseMarkup}
                action={'write'}
              >
                <li className="nav-item">
                  <NavLink
                    to="/purchases/commissions"
                    className="nav-link text-decoration-none"
                  >
                    <FormattedMessage
                      defaultMessage="Комиссии за выкуп"
                      id="orders.label.redemptionFees"
                      description="Надпись на вкладке"
                    />
                  </NavLink>
                </li>
              </Rbac>
              <Rbac object={RbacObject.AutoSendParameters} action={'read'}>
                <li className="nav-item">
                  <NavLink
                    to="/purchases/auto-send"
                    className="nav-link text-decoration-none"
                  >
                    <FormattedMessage
                      defaultMessage="Авто-отправка"
                      id="purchasesScreen.link.autoSend"
                      description="Надпись на вкладке"
                    />
                  </NavLink>
                </li>
              </Rbac>
              <Rbac
                object={RbacObject.ServiceChargeOption}
                action={'read:admin'}
              >
                <li className="nav-item">
                  <NavLink
                    to="/purchases/service-charge-option"
                    className="nav-link text-decoration-none"
                  >
                    <FormattedMessage
                      defaultMessage="Доп.расходы"
                      id="orders.label.serviceChargeOption"
                      description="Надпись на вкладке"
                    />
                  </NavLink>
                </li>
              </Rbac>
            </ul>
          </RbacSome>
          <Outlet />
        </div>
      </SelectedOrdersContext.Provider>
    </>
  );
};
