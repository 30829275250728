import React from 'react';
import { NavLink, Outlet, useOutletContext } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { OutletContextParams } from './delivery-orders-screen';
import { TransportationLinesCount } from './transportation-lines-count';

export const DeliveryOrdersAssemblyTabs = () => {
  const intl = useIntl();
  const context: OutletContextParams = useOutletContext();

  const tabs = [
    {
      title: intl.formatMessage({
        id: 'deliveryOrders.tab-label.assembly-standalone',
        defaultMessage: 'Индивидуальные',
        description: 'Надпись на вкладке',
      }),
      path: 'standalone',
      type: 'standalone' as const,
    },
    {
      title: intl.formatMessage({
        id: 'deliveryOrders.tab-label.assembly-combined',
        defaultMessage: 'Сборные',
        description: 'Надпись на вкладке',
      }),
      path: 'combined',
      type: 'combined' as const,
    },
  ];

  return (
    <>
      <div className="mb-4">
        <ul className="nav nav-pills d-inline-flex">
          {tabs.map((item) => (
            <li className="nav-item" key={item.title}>
              <NavLink to={item.path} className="nav-link text-decoration-none">
                {item.title} (
                <TransportationLinesCount
                  name={context.debouncedInternalId}
                  status={['created']}
                  types={[item.type]}
                  linked={context.linked}
                  supplierId={context.selectSupplier}
                />
                )
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <Outlet context={context} />
    </>
  );
};
