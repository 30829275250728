import {
  CleanDeliveryOrder,
  CleanPurchaseOrder,
  CleanTransportation,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { DeliveryOrdersTabItem } from './delivery-orders-tab-item';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import tableStyle from '../../row.module.css';
import { Rbac, useRbac } from '../../common/Rbac';
import { Link, useNavigate } from 'react-router-dom';
import { TariffCell } from '../../common/cards/tariff-cell-card.component';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { LocalDelivery } from '../../common/cards/local-delivery-cell-card.component';
import { Manager } from '../../common/cards/manager-cell-card.component';
import { toMoney } from '../../../utils/common.utils';
import { useProfile } from '../../../api/profile';
import { UserInternalIdCell } from '../../common/cards/id-user-cell-card.component';
import { ExpandButton, Row } from '../../row';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { DeliveryPeriodCell } from '../../common/cards/delivery-date-cell-card.component';
import { WeightVolumeCell } from '../../common/cards/weight-volume-cell-card.component';
import { PriceCell } from '../../common/cards/price-cell-card.component';

type TransportationTabItemProps = {
  data: CleanTransportation;
  status: 'assembly' | 'sentToRussia';
  collapsed?: boolean;
  defaultCollapsed?: boolean;
  onCollapsedChange?: () => void;
};

export function TransportationTabItem(props: TransportationTabItemProps) {
  const { data: profile } = useProfile();
  const { hasPermission } = useRbac();
  const navigate = useNavigate();

  const href = `/transportations/${props.data.id}`;

  const canReadExtendedData = hasPermission(
    RbacObject.DeliveryOrder,
    'read:finances-client',
  );

  if (props.data.lines.length === 0) return null;

  if (props.data.type === 'standalone') {
    const deliveryOrder = props.data.lines[0].deliveryOrder;

    return (
      <DeliveryOrdersTabItem
        data={deliveryOrder}
        onCollapsedChange={props.onCollapsedChange}
        collapsed={props.collapsed}
        defaultCollapsed={props.defaultCollapsed}
        status={props.status}
      />
    );
  }

  const handleClick = useCallback(() => {
    navigate(href);
  }, [navigate, href]);

  return (
    <Row
      onClick={handleClick}
      collapsed={props.collapsed}
      defaultCollapsed={props.defaultCollapsed}
      onCollapsedChange={props.onCollapsedChange}
      subRows={props.data.lines.map(
        ({ deliveryOrder }) =>
          deliveryOrder && (
            <div
              className={classNames(
                'row cursor-pointer g-0',
                tableStyle.tableSubRow,
              )}
              key={deliveryOrder.id}
            >
              <div className="col pe-1">
                <Link
                  to={`/deliveries/delivery-order/${deliveryOrder.id}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <IdCell
                    internalId={deliveryOrder.internalId}
                    type={'delivery'}
                  />
                </Link>
              </div>
              <div className="col">
                <UserInternalIdCell user={deliveryOrder.user} />
              </div>
              <div className="col" />
              <div className="col-2" />
              {props.status === 'assembly' && props.data.createdAt ? (
                <div className="col-2 full-text">
                  <div className="color-gray-800 fs-16 fw-500">
                    {moment(props.data.createdAt).format('DD.MM.YYYY')}
                  </div>
                  <div className="color-gray-600 fs-14">
                    <FormattedMessage
                      defaultMessage="Поступил на комплектацию"
                      id="order.status-label.ReceivedForCompletion"
                      description="Статус заказа"
                    />
                  </div>
                </div>
              ) : null}
              {canReadExtendedData ? (
                <div className="col-2">
                  <LocalDelivery
                    localDelivery={deliveryOrder.localDeliveryMethod}
                  />
                </div>
              ) : null}
              {canReadExtendedData && (
                <div className="col-1">
                  {deliveryOrder.user?.manager && (
                    <Manager manager={deliveryOrder.user.manager} />
                  )}
                </div>
              )}
              {profile && !profile.accesses.includes('fulfillment') && (
                <div className="col-sm-2 col-xl-2 text-end">
                  <div className="">
                    <div className="color-gray-800 fs-16 fw-500  text-truncate">
                      {toMoney(
                        deliveryOrder.moderateProductPrice!,
                        deliveryOrder.supplier?.purchaseCurrency || 'cny',
                      )}
                    </div>
                    <div className="color-gray-600 fs-14 fw-400">
                      <FormattedMessage
                        defaultMessage="Стоимость товаров"
                        id="delivery.label.priceOfGoods"
                        description="Стоимость товаров"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ),
      )}
    >
      {({ collapsed, setCollapsed }) => (
        <>
          {canReadExtendedData && (
            <Rbac
              object={RbacObject.DeliveryOrder}
              action="read:finances-client"
            >
              <div className="col-1">{props.data.supplier?.name ?? ''}</div>
            </Rbac>
          )}
          <div className="col pe-1">
            <Link
              to={href}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {props.data.name}
            </Link>
          </div>
          <div className="col" />
          <div className="col">
            <ExpandButton
              onToggle={() => setCollapsed(!collapsed)}
              collapsed={collapsed}
            />
          </div>
          {!props.data.waybill && (
            <>
              <div className="col-2">
                <TariffCell
                  deliveryMethod={{
                    method: props.data.methodType,
                    deliveryPeriodFrom: props.data.deliveryPeriodFrom,
                    deliveryPeriodTo: props.data.deliveryPeriodTo ?? undefined,
                  }}
                />
              </div>
              <div className="col-2 full-text">
                <div className="color-gray-800 fs-16 fw-500">
                  {moment(props.data.createdAt).format('DD.MM.YYYY')}
                </div>
                <div className="color-gray-600 fs-14">
                  <FormattedMessage
                    defaultMessage="Поступил на комплектацию"
                    id="order.status-label.ReceivedForCompletion"
                    description="Статус заказа"
                  />
                </div>
              </div>
              <div className="col-2" />
              <div className="col-1" />
              {profile && !profile.accesses.includes('fulfillment') && (
                <div className="col-sm-2 col-xl-2 text-end" />
              )}
            </>
          )}
          {props.data.waybill && (
            <>
              <div
                className={classNames(
                  canReadExtendedData ? 'col-2' : 'col-3',
                  'd-flex',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={props.data.waybill.packedFiles} />
              </div>
              <div className="col-sm-2 col-xl-3 d-flex align-items-center">
                <DeliveryPeriodCell
                  deliveryMethod={{
                    method: props.data.methodType,
                    deliveryPeriodTo: props.data.deliveryPeriodTo,
                  }}
                  sentDate={props.data.waybill.createdAt}
                  addHover={true}
                />
              </div>
              {canReadExtendedData && <div className="col-1" />}
              <div
                className={classNames(
                  canReadExtendedData ? 'col-1' : 'col-2 text-end',
                )}
              >
                <WeightVolumeCell
                  weight={props.data.waybill.weight}
                  volume={props.data.waybill.volume}
                />
              </div>
              {canReadExtendedData && <div className="col-1" />}
              {canReadExtendedData && (
                <div className="col-1 justify-content-end d-flex align-items-center" />
              )}
            </>
          )}
        </>
      )}
    </Row>
  );
}
