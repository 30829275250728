import React, { useState } from 'react';
import { SupplierInfoRequestActions } from './supplier-info-request-actions';
import {
  CleanPurchaseOrder,
  RbacObject,
} from '../../export-types/cleaned-types';
import { Rbac } from './Rbac';
import useSWR from 'swr';
import { fetcher } from '../../services/api';
import { SupplierInfoEdit } from './SupplierInfoEdit';

export type SupplierProps = {
  supplier?: string | null;
  purchaseOrderId?: string;
};

export const SupplierInfo: React.FC<SupplierProps> = ({
  supplier,
  purchaseOrderId,
}) => {
  const { data: order, mutate: orderMutate } = useSWR<CleanPurchaseOrder>(
    purchaseOrderId ? `/purchase-order/${purchaseOrderId}` : null,
    fetcher,
  );

  const [editMode, setEditMode] = useState(false);

  function handleEdit() {
    setEditMode(true);
  }

  function handleChange() {
    orderMutate();
  }

  function handleSave(data: CleanPurchaseOrder) {
    orderMutate(data);
    setEditMode(false);
  }

  function handleCancel() {
    setEditMode(false);
  }

  if (editMode) {
    return (
      <SupplierInfoEdit
        order={order}
        supplier={supplier}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
  }

  const allowEditInStatus = ['waitingForPayment', 'hiddenFrozen'];

  return (
    <div className="bg-gray p-3 rounded-8 mt-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Поставщик</div>
        {order?.status && allowEditInStatus.includes(order.status) ? (
          <Rbac object={RbacObject.Purchase} action={'write:supplier'}>
            <SupplierInfoRequestActions
              order={order}
              onChange={handleChange}
              onEdit={handleEdit}
            />
          </Rbac>
        ) : null}
      </div>
      <div>{supplier ?? ''}</div>
    </div>
  );
};
