import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  accountValidation,
  bicValidation,
  innValidation,
  kppValidation,
  ogrnValidation,
  required,
} from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import ReactSelect from 'react-select';
import { useModalClose } from '../../../utils/useModalClose';
import { Company, InnSelect } from '../../common/inn-select';
import { useIntl } from 'react-intl';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { uploadMediaInputFiles } from '../../../services/file-service';

export const vatValueNames = {
  0: 'Без НДС',
  5: 'НДС 5%',
  7: 'НДС 7%',
  10: 'НДС 10%',
  20: 'НДС 20%',
};

type Form = {
  name: string;
  shortName: string;
  inn: string;
  account: string;
  address: string;
  bankName: string;
  bankBic: string;
  bankAccount: string;
  bankAddress: string;
  bankInn: string;
  kpp?: string;
  ogrn?: string;
  currencies: Array<{ value: string }>;
  signing: MediaInputValue[];
  stamp?: MediaInputValue[];
  vatValue?: { label: string; value: number };
};

type SendForm = {
  name: string;
  shortName: string;
  inn: string;
  account: string;
  address: string;
  bankName: string;
  bankBic: string;
  bankAccount: string;
  bankAddress: string;
  bankInn: string;
  kpp?: string;
  ogrn?: string;
  currencies: Array<string>;
  signing?: { id: string };
  stamp?: { id: string };
  vatValue?: number;
};

type AddBankCardModelProps = {
  close: () => void;
  reject: () => void;
  defaultValues?: Form;
  onSubmit: (data: SendForm) => Promise<void>;
  buttonTitle: string;
};

export const AddInvoiceAccountModel: React.FC<AddBankCardModelProps> = ({
  close,
  reject,
  defaultValues,
  onSubmit,
  buttonTitle,
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<Form>({
    defaultValues: {
      account: '',
      address: '',
      shortName: '',
      name: '',
      inn: '',
      ogrn: '',
      kpp: '',
      bankAccount: '',
      bankName: '',
      bankInn: '',
      bankAddress: '',
      bankBic: '',
      vatValue: { label: 'Без НДС', value: 0 },
      ...defaultValues,
    },
  });

  const intl = useIntl();
  const [company, setCompany] = useState<Company | null>(null);
  const [bankCompany, setBankCompany] = useState<Company | null>(null);

  const inn = watch('inn');
  const bankInn = watch('bankInn');

  useEffect(() => {
    if (company) {
      setValue('inn', company?.inn);
      setValue('name', company?.full_name || '');
      setValue('address', company.address);
      setValue('shortName', company?.name);
      setValue('ogrn', company?.ogrn);
      setValue('kpp', company?.kpp);
    }
  }, [company]);

  useEffect(() => {
    if (bankCompany) {
      setValue('bankName', bankCompany?.name || '');
      setValue('bankAddress', bankCompany.address);
      setValue('bankInn', bankCompany.inn);
    }
  }, [bankCompany]);

  async function onSave(data: Form) {
    const fileResponse = await uploadMediaInputFiles([
      ...data.signing,
      ...(data.stamp || []),
    ]);

    onSubmit({
      ...data,
      vatValue: data.vatValue?.value,
      signing:
        fileResponse.length > 0
          ? {
              id: fileResponse[0].file.id,
            }
          : undefined,
      stamp:
        fileResponse.length > 1
          ? {
              id: fileResponse[1].file.id,
            }
          : undefined,
      currencies: data.currencies.map((option) => option.value),
    }).then(() => {
      close();
    });
  }

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить юридическое лицо</h3>
            <div className="btn p-0" onClick={reject}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <Controller
              name="inn"
              control={control}
              rules={{
                required: intl.formatMessage({
                  defaultMessage: 'Укажите ИНН',
                  id: 'form.label.indicateTheTINOfTheOrganization...',
                  description: 'Пояснение к полю ввода',
                }),
                ...innValidation,
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title={intl.formatMessage({
                      defaultMessage: 'ИНН',
                      id: 'form.input-label.nameOfOrganizationOrIndividualEntrepreneur',
                      description: 'Заголовок поля ввода',
                    })}
                    error={fieldState.error?.message}
                  >
                    {!inn ? (
                      <InnSelect
                        {...field}
                        value={
                          field.value
                            ? ({
                                inn: field.value,
                                name: field.value,
                              } as Company)
                            : null
                        }
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Введите название компании или ИНН',
                          id: 'form.input-label.AddInvoiceAccountInn',
                          description: 'Поле ввода',
                        })}
                        onSelectCompany={(selectedCompany) => {
                          setCompany(selectedCompany);
                        }}
                      />
                    ) : (
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          value={field.value}
                          onChange={(e) => {
                            if (!e.currentTarget.value) {
                              setCompany(null);
                            }
                            field.onChange(e.currentTarget.value);
                          }}
                        />
                      </div>
                    )}
                  </InputWrapper>
                );
              }}
            />
          </div>
          {inn ? (
            <>
              <div className="row mt-3">
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите наименование',
                      id: 'form.label.AddInvoiceAccountName',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Наименование"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  name="shortName"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите сокращенное наименование',
                      id: 'form.label.AddInvoiceAccountShortName',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Сокращенное наименование"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  name="ogrn"
                  control={control}
                  rules={{
                    ...ogrnValidation,
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="ОГРН"
                        className="col"
                        error={fieldState.error?.message}
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
                <Controller
                  name="kpp"
                  control={control}
                  rules={{ ...kppValidation }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="КПП"
                        className="col"
                        error={fieldState.error?.message}
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  name="account"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите счет',
                      id: 'form.label.AddInvoiceAccountAccount',
                      description: 'Пояснение к полю ввода',
                    }),
                    ...accountValidation,
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Счет"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите адрес',
                      id: 'form.label.AddInvoiceAccountAddress',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Адрес"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  control={control}
                  name="vatValue"
                  rules={{
                    ...required,
                  }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title="Сумма НДС"
                      required
                      error={fieldState.error?.message}
                    >
                      <ReactSelect
                        placeholder="Выберите счета"
                        options={[
                          { label: vatValueNames['0'], value: 0 },
                          { label: vatValueNames['5'], value: 5 },
                          { label: vatValueNames['7'], value: 7 },
                          { label: vatValueNames['10'], value: 10 },
                          { label: vatValueNames['20'], value: 20 },
                        ]}
                        isSearchable={false}
                        isMulti={false}
                        getOptionLabel={(option) => option.label}
                        {...field}
                      />
                    </InputWrapper>
                  )}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  control={control}
                  name="currencies"
                  rules={{
                    ...required,
                  }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title="Разрешенные к пополнению счета"
                      required
                      error={fieldState.error?.message}
                    >
                      <ReactSelect
                        placeholder="Выберите счета"
                        options={[{ value: 'cny' }, { value: 'usd' }]}
                        isSearchable={false}
                        isMulti={true}
                        getOptionLabel={(option) => option.value?.toUpperCase()}
                        getOptionValue={(option) => option.value}
                        {...field}
                      />
                    </InputWrapper>
                  )}
                />
              </div>
              <div className="row">
                <Controller
                  control={control}
                  name="signing"
                  rules={{
                    required: intl.formatMessage({
                      id: 'deliveryOrderAssemblyModal.title.AddInvoiceAccountSigning',
                      defaultMessage: 'Загрузите фото подписи',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title={intl.formatMessage({
                        defaultMessage: 'Фото подписи',
                        id: 'deliveryAssemblyModal.input-label.AddInvoiceAccountSigningPhoto',
                        description: 'Заголовок поля ввода',
                      })}
                      className="mb-3"
                      error={fieldState.error?.message}
                      required
                    >
                      <MediaInput
                        {...field}
                        enableClipboardPasteHandler={false}
                      />
                    </InputWrapper>
                  )}
                />
                <Controller
                  control={control}
                  name="stamp"
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title={intl.formatMessage({
                        defaultMessage: 'Фото печати',
                        id: 'deliveryAssemblyModal.input-label.AddInvoiceAccountStampPhoto',
                        description: 'Заголовок поля ввода',
                      })}
                      className="mb-3"
                      error={fieldState.error?.message}
                    >
                      <MediaInput
                        {...field}
                        enableClipboardPasteHandler={false}
                      />
                    </InputWrapper>
                  )}
                />
              </div>
            </>
          ) : null}
          <div className="row mt-3">
            <Controller
              name="bankInn"
              control={control}
              rules={{
                required: intl.formatMessage({
                  defaultMessage: 'Укажите ИНН',
                  id: 'form.label.AddInvoiceAccountSigningInn',
                  description: 'Пояснение к полю ввода',
                }),
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title={intl.formatMessage({
                      defaultMessage: 'ИНН Банка',
                      id: 'form.input-label.AddInvoiceAccountBankInn',
                      description: 'Заголовок поля ввода',
                    })}
                    error={fieldState.error?.message}
                  >
                    {!bankInn ? (
                      <InnSelect
                        {...field}
                        value={
                          field.value
                            ? ({
                                inn: field.value,
                                name: field.value,
                              } as Company)
                            : null
                        }
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Введите название компании или ИНН',
                          id: 'form.input-label.enterInvoiceAccountBankInn',
                          description: 'Поле ввода',
                        })}
                        onSelectCompany={(selectedCompany) => {
                          setBankCompany(selectedCompany);
                        }}
                      />
                    ) : (
                      <div className="input-group">
                        <input
                          className="form-control"
                          type="text"
                          value={field.value}
                          onChange={(e) => {
                            if (!e.currentTarget.value) {
                              setBankCompany(null);
                            }
                            field.onChange(e.currentTarget.value);
                          }}
                        />
                      </div>
                    )}
                  </InputWrapper>
                );
              }}
            />
          </div>
          {bankInn ? (
            <>
              <div className="row mt-3">
                <Controller
                  name="bankName"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите наименование банка',
                      id: 'form.label.enterInvoiceAccountBankName...',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Наименование"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  name="bankAddress"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите адрес',
                      id: 'form.label.enterInvoiceAccountBankAddress',
                      description: 'Пояснение к полю ввода',
                    }),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Адрес банка"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
              <div className="row mt-3">
                <Controller
                  name="bankAccount"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите счет',
                      id: 'form.label.enterInvoiceAccountBankAccount',
                      description: 'Пояснение к полю ввода',
                    }),
                    ...accountValidation,
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Корреспондентский счет"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
                <Controller
                  name="bankBic"
                  control={control}
                  rules={{
                    required: intl.formatMessage({
                      defaultMessage: 'Укажите БИК',
                      id: 'form.label.enterInvoiceAccountBankBic',
                      description: 'Пояснение к полю ввода',
                    }),
                    ...bicValidation,
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="БИК"
                        className="col"
                        error={fieldState.error?.message}
                        required
                      >
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </div>
                      </InputWrapper>
                    );
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button type="submit" className="btn btn-success">
            {buttonTitle}
          </button>
        </div>
      </form>
    </div>
  );
};
