import React, { useMemo } from 'react';
import { InputWrapper } from '../../common/input-wrapper';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { getDeliveryPeriod, toMoney } from '../../../utils/common.utils';
import { NavLink } from 'react-router-dom';
import { IdCell } from '../../common/cards/id-cell-card.component';
import {
  CleanDeliveryOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { useProfile } from '../../../api/profile';
import { FormattedMessage, useIntl } from 'react-intl';
import { Rbac } from '../../common/Rbac';
import { DeliveryOrderRequestInfoActions } from './delivery-order-request-info-actions';
import { RequestAddressEditForm } from './request-address-edit-form';
import { RequestDeliveryMethodEditForm } from './request-delivery-method-edit-form';
import { Decimal } from 'decimal.js';
import { useOrderDeliveryPaths } from '../purchase-order/useOrderPaths';

type DeliveryOrderDeliveryInfoProps = {
  id: string;
};
export const DeliveryOrderDeliveryInfo: React.FC<
  DeliveryOrderDeliveryInfoProps
> = (props) => {
  const [editModeAddress, setEditModeAddress] = React.useState(false);
  const [editModeDeliveryMethod, setEditModeDeliveryMethod] =
    React.useState(false);
  const { data: user } = useProfile();
  const { data: order, mutate } = useSWR<CleanDeliveryOrder>(
    `/delivery-order/${props.id}`,
    fetcher,
  );
  const intl = useIntl();
  const { countryFrom } = useOrderDeliveryPaths({ orderId: props.id });
  if (!order || !user) {
    return null;
  }

  const totalWeight = useMemo(() => {
    if (order.purchaseOrders) {
      const total = order.purchaseOrders?.reduce(
        (a, b) => a.plus(new Decimal(b.weight || 0)),
        new Decimal(0),
      );
      return total.toFixed(2);
    }
    return '';
  }, [order]);

  const totalVolume = useMemo(() => {
    if (order.purchaseOrders) {
      const total = order.purchaseOrders?.reduce(
        (a, b) => a.plus(new Decimal(b.volume || 0)),
        new Decimal(0),
      );
      return total.toFixed(3);
    }

    return '';
  }, [order]);

  function handleEditAddress() {
    setEditModeAddress(true);
  }
  function handleEditDeliveryMethod() {
    setEditModeDeliveryMethod(true);
  }
  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="О заказе"
            id="orderInfo.label.aboutOrder"
            description="Информация о заказе"
          />
        </div>
        <Rbac object={RbacObject.DeliveryOrder} action={'write:address'}>
          <DeliveryOrderRequestInfoActions
            order={order}
            onEditDeliveryMethod={handleEditDeliveryMethod}
            onEditAddress={handleEditAddress}
          />
        </Rbac>
      </div>
      <table className="table table-xs table-borderless mt-3">
        <thead className="color-gray-600">
          <tr>
            <td>
              <FormattedMessage
                defaultMessage="Выкупы в доставке"
                id="deliveryInfo.label.ordersIsInDelivery"
                description="Подзаголовок на странице"
              />
            </td>
            <td className="text-end">
              <FormattedMessage
                defaultMessage="Место хранения"
                id="storage.label.storagePlace"
                description="Информация о месте хранения"
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {order.purchaseOrders?.map(
            ({ internalId, type, id, storageLocation }, i) => (
              <tr key={i}>
                <td>
                  <NavLink
                    className="btn-link cursor-pointer text-decoration-underline"
                    to={`/${
                      type === 'purchase' ? 'purchases' : 'parcels'
                    }/${id}`}
                  >
                    <IdCell internalId={internalId} type={type} />
                  </NavLink>
                </td>
                <td className="text-end">{storageLocation}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      {!user?.accesses.includes('fulfillment') && (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage({
            defaultMessage: 'Общая стоимость товаров в заказе',
            id: 'deliveryInfo.input-label.totalCostOfGoodsInTheOrder',
            description: 'Заголовок поля ввода',
          })}
        >
          {toMoney(
            order.moderateProductPrice!,
            order.supplier?.purchaseCurrency || 'cny',
          )}
        </InputWrapper>
      )}
      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Общий вес посылок',
          id: 'delivery.input-label.totalWeightOfParcels',
          description: 'Заголовок поля ввода',
        })}
      >
        <FormattedMessage
          defaultMessage="{value} кг"
          id="delivery.labelTotalWeight.kg"
          description="Единица измерения"
          values={{ value: totalWeight }}
        />
      </InputWrapper>
      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Общий объем посылок',
          id: 'delivery.input-label.totalVolumeOfParcels',
          description: 'Заголовок поля ввода',
        })}
      >
        <FormattedMessage
          defaultMessage="{value} м³"
          id="delivery.label.length"
          description="Единица измерения"
          values={{ value: totalVolume }}
        />
      </InputWrapper>
      <InputWrapper
        theme="light"
        className="mt-3"
        title={intl.formatMessage({
          defaultMessage: 'Упаковка',
          id: 'delivery.title-label.packaging',
          description: 'Заголовок поля ввода',
        })}
      >
        {order.packageGroups?.length === 0
          ? order.packageMethods
          : order.packageGroups
              ?.flatMap((group) => group.methods)
              .map((method) => method.name)
              .join(', ')}
      </InputWrapper>
      {editModeDeliveryMethod ? (
        <RequestDeliveryMethodEditForm
          id={order.id}
          deliveryMethod={order.deliveryMethod?.name}
          purchaseOrders={
            order.purchaseOrders?.map((purchase) => purchase.id) ?? []
          }
          onCancel={() => {
            setEditModeDeliveryMethod(false);
          }}
          onSave={() => {
            mutate();
            setEditModeDeliveryMethod(false);
          }}
        />
      ) : (
        <InputWrapper
          theme="light"
          className="mt-3"
          title={intl.formatMessage(
            {
              defaultMessage: 'Доставка {country} —  РФ',
              id: 'deliveryInfo.input-label.deliveryChinaRussia',
              description: 'Заголовок поля ввода',
            },
            { country: countryFrom },
          )}
        >
          {order.deliveryMethod ? (
            <>
              {order.deliveryMethod.name},{' '}
              {getDeliveryPeriod(order.deliveryMethod)}
            </>
          ) : null}
        </InputWrapper>
      )}
      {editModeAddress && order.address ? (
        <RequestAddressEditForm
          supplierId={order.supplier?.id}
          defaultValues={{
            localDelivery: order.localDeliveryMethod.id,
            type: order.address?.type,
            name: order.address.name,
            city: order.address?.city,
            companyInn: order.address?.companyInn ?? undefined,
            phone: order.address.phone,
            companyName: order.address?.companyName ?? undefined,
            passport: order.address?.passport ?? undefined,
            pickupPoint: order.address?.pickupPoint ?? undefined,
            cdekCode: order.cdekOrder?.pointCode ?? undefined,
            isCdek: order.localDeliveryMethod.isCdek,
          }}
          id={order.id}
          onCancel={() => {
            setEditModeAddress(false);
          }}
          onSave={() => {
            mutate();
            setEditModeAddress(false);
          }}
        />
      ) : (
        <>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Доставка по РФ',
              id: 'deliveryInfo.input-label.shippingWithinTheRussianFederation',
              description: 'Заголовок поля ввода',
            })}
          >
            {order.localDeliveryMethod.name}
          </InputWrapper>
          <Rbac object={RbacObject.DeliveryOrder} action={'read:address'}>
            <InputWrapper
              theme="light"
              className="mt-3"
              title={intl.formatMessage({
                defaultMessage: 'Адрес доставки и получатель',
                id: 'deliveryInfo.input-label.shippingAddressAndRecipient',
                description: 'Заголовок поля ввода',
              })}
            >
              {order.address ? (
                <>
                  <div>
                    г.{order.address.city}, {order.address.name}
                  </div>
                  {order.address.type === 'legal' && (
                    <div>
                      {order.address.companyName}, ИНН{' '}
                      {order.address.companyInn}
                    </div>
                  )}
                  {order.address.type === 'individual' && (
                    <div>паспорт {order.address.passport}</div>
                  )}
                  {order.address.type === 'pickupPoint' && (
                    <div>ПВЗ {order.address.pickupPoint}</div>
                  )}
                  <div>{order.address.phone}</div>
                </>
              ) : (
                <div>Не заполнен клиентом</div>
              )}
            </InputWrapper>
          </Rbac>
        </>
      )}
    </div>
  );
};
