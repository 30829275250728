import useSWR from 'swr';
import { CleanTransportation } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

export function useTransportation(id: string | null) {
  return useSWR<CleanTransportation>(
    {
      url: id ? `/transportation/${id}` : null,
    },
    fetcher,
  );
}

export type UseTransportationLinesCountParams = {
  linked?: boolean;
  types?: string[];
  status?: string[];
  name?: string;
  supplierId?: number;
};

export function useTransportationLinesCount(
  params?: UseTransportationLinesCountParams,
) {
  return useSWR<number>(
    {
      url: '/transportation/lines-count',
      params,
    },
    fetcher,
  );
}

type UseTransportationsParams = {
  linked?: boolean;
  types?: string[];
  status?: string[];
  take?: number;
  skip?: number;
  name?: string;
  supplierId?: number;
  direction?: string;
  sort?: string;
};

export function useTransportations(params?: UseTransportationsParams) {
  return useSWR<{
    total: number;
    items: Array<CleanTransportation>;
  }>(
    {
      url: '/transportation',
      params,
    },
    fetcher,
  );
}
