import React from 'react';
import { useTransportationLinesCount } from '../../../api/transportations';

type TransportationLinesCountProps = {
  linked?: boolean;
  types?: Array<'standalone' | 'combined'>;
  status?: Array<'created' | 'sent' | 'received'>;
  name?: string;
  supplierId?: number;
};

export function TransportationLinesCount(props: TransportationLinesCountProps) {
  const { data: count, isLoading } = useTransportationLinesCount({
    linked: props.linked,
    types: props.types,
    status: props.status,
    name: props.name,
    supplierId: props.supplierId,
  });

  if (isLoading) {
    return (
      <>
        <i className="bi bi-three-dots color-gray-400" />
      </>
    );
  }

  return <>{count}</>;
}
