import React from 'react';
import { useUncontrolled } from '@mantine/hooks';
import classNames from 'classnames';
import tableStyle from './row.module.css';

type ExpandButtonProps = {
  onToggle: () => void;
  collapsed: boolean;
};

export function ExpandButton(props: ExpandButtonProps) {
  return (
    <button
      type="button"
      className="btn d-flex flex-row justify-content-center"
      onClick={(e) => {
        e.stopPropagation();
        props.onToggle();
      }}
    >
      {!props.collapsed ? (
        <i className={`bi bi-chevron-up`} />
      ) : (
        <i className={`bi bi-chevron-down`} />
      )}
    </button>
  );
}

type RowProps = {
  onClick?: () => void;
  collapsed?: boolean;
  defaultCollapsed?: boolean;
  onCollapsedChange?: (value: boolean) => void;
  subRows?: React.ReactNode;
  children: (props: {
    collapsed: boolean;
    setCollapsed: (value: boolean) => void;
  }) => React.ReactNode;
};

export function Row(props: RowProps) {
  const [collapsed, setCollapsed] = useUncontrolled<boolean>({
    value: props.collapsed,
    defaultValue: props.defaultCollapsed,
    finalValue: false,
    onChange: props.onCollapsedChange,
  });

  return (
    <div className={classNames('row g-0 mb-3', tableStyle.rowContainer)}>
      <div
        className={classNames(
          'position-relative row g-0',
          props.onClick && 'cursor-pointer',
          tableStyle.tableRow,
        )}
        onClick={props.onClick}
      >
        {props.children({ collapsed, setCollapsed })}
      </div>
      {!collapsed && props.subRows}
    </div>
  );
}

type SubRowProps = {
  onClick?: () => void;
};

export function SubRow(props: React.PropsWithChildren<SubRowProps>) {
  return (
    <div
      className={classNames(
        'row g-0',
        props.onClick && 'cursor-pointer',
        tableStyle.tableSubRow,
      )}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
