import useSWR from 'swr';
import { CleanPurchaseOrder } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

export type Params = {
  sort?: string;
  direction?: string;
  linked?: boolean;
  overdue?: boolean;
  status?: Array<string>;
  take?: number;
  skip?: number;
  internalId?: string;
  supplierId?: number;
  all?: boolean;
};

export function usePurchaseOrders(params: Params = {}) {
  const { data, error, isLoading, mutate } = useSWR<{
    items: Array<CleanPurchaseOrder>;
    total: number;
  }>({ url: '/purchase-order/v2', params: params }, fetcher);

  return { data, error, isLoading, mutate };
}
