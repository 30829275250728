export const IconAdminAssistance = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 172 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M171.524 181.574C169.275 171.275 157.545 131.22 112.493 116.915C117.279 113.905 121.521 110.107 125.032 105.699H142.555C152.713 105.699 160.977 97.4353 160.977 87.2783V62.1665C160.977 57.9099 159.522 53.9892 157.088 50.8665C133.597 -16.8966 38.5983 -17.0147 15.0197 50.8673C12.5868 53.9899 11.1318 57.9103 11.1318 62.1665V87.2783C11.1318 97.4357 19.3955 105.699 29.5525 105.699H47.0767C50.5869 110.107 54.8294 113.905 59.615 116.915C14.5802 131.211 2.76795 171.256 0.496925 181.538C-1.47849 190.23 5.06868 200 15.4462 200C45.9869 200 127.115 200 156.662 199.906C166.781 199.905 173.446 190.545 171.524 181.574ZM149.246 62.1665V87.2783C149.246 90.9675 146.245 93.9686 142.556 93.9686H132.008C137.137 81.948 137.137 67.4964 132.008 55.4758H142.556C146.245 55.4754 149.246 58.4768 149.246 62.1665ZM40.1006 93.9686H29.5525C25.8633 93.9686 22.8623 90.9671 22.8623 87.2783V62.1665C22.8623 58.4768 25.8637 55.4758 29.5525 55.4758H40.1006C34.9716 67.4964 34.9716 81.9476 40.1006 93.9686ZM31.1193 43.7449C54.6601 0.898174 117.462 0.927502 140.99 43.7449H125.032C105.864 18.8059 66.2357 18.8153 47.0767 43.7449H31.1193ZM47.9737 74.722C47.9737 53.7244 65.0564 36.6417 86.054 36.6417C111.945 36.1373 131.075 63.6281 121.859 87.6908H98.6103C90.84 87.9778 90.8392 99.1343 98.6103 99.4213H115.013C92.4784 126.093 47.9749 110.057 47.9737 74.722ZM159.345 186.901C158.686 187.711 157.708 188.175 156.644 188.175C127.096 188.27 45.9823 188.27 15.4462 188.27C13.2824 188.323 11.4782 186.147 11.9611 184.022C30.0585 106.026 141.064 104.3 160.054 184.036C160.258 185.053 160 186.097 159.345 186.901Z"
        fill="#0079BB"
      />
      <path
        d="M40.1006 93.9686H29.5525C25.8633 93.9686 22.8623 90.9671 22.8623 87.2783V62.1665C22.8623 58.4768 25.8637 55.4758 29.5525 55.4758H40.1006C34.9716 67.4964 34.9716 81.9476 40.1006 93.9686Z"
        fill="#0079BB"
      />
      <path
        d="M149.246 62.1665V87.2783C149.246 90.9675 146.245 93.9686 142.556 93.9686H132.008C137.137 81.948 137.137 67.4964 132.008 55.4758H142.556C146.245 55.4754 149.246 58.4768 149.246 62.1665Z"
        fill="#0079BB"
      />
    </svg>
  );
};
