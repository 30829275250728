import React from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { useModalClose } from '../../../utils/useModalClose';

type PurchaseOrderFreezeManyModalProps = {
  close: () => void;
  reject: () => void;
  orderIds: string[];
};

export const PurchaseOrderHiddenFreezeManyModal: React.FC<
  PurchaseOrderFreezeManyModalProps
> = ({ close, orderIds, reject }) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(() => {
    api
      .post(`/purchase-order/hiddenFreezeOrders`, {
        ids: orderIds,
      })
      .then(() => {
        close();
      });
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-gray-600 fs-65 lh-1" />
            <h3 className="mt-3">Скрытая заморозка</h3>
            <div className="mt-3">
              Скрывает заказы от поставщика. Данные заказы будут видны только
              менеджерам и админам, пока вы их не разморозите
            </div>
            <div className="mt-3">Подтвердить скрытую заморозку?</div>
          </div>

          <div className="d-flex gap-2">
            <button className="btn btn-success w-50">Подтвердить</button>
            <button
              type="button"
              className="btn btn-secondary text-white w-50"
              onClick={() => reject()}
            >
              Отмена
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
