import React from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { required } from '../../../utils/validations';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModalClose } from '../../../utils/useModalClose';

type PurchaseOrderFreezeModalProps = {
  close: () => void;
  reject: () => void;
  order: {
    id: string;
  };
};

export const PurchaseOrderHiddenFreezeModal: React.FC<
  PurchaseOrderFreezeModalProps
> = ({ close, order, reject }) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(() => {
    api.post(`/purchase-order/${order.id}/hidden-freeze`).then(() => {
      close();
    });
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog mw-400" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-header border-bottom-0 p-4">
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
          />
        </div>
        <form className="modal-body pt-2 p-4" onSubmit={onSubmit}>
          <div className="text-center mb-4 mx-auto">
            <i className="bi bi-exclamation-circle-fill color-gray-600 fs-65 lh-1" />
            <h3 className="mt-3">Скрытая заморозка</h3>
            <div className="mt-3">
              Скрывает заказ от поставщика. Данный заказ будет виден только
              менеджерам и админам, пока вы его не разморозите
            </div>
            <div className="mt-3">Подтвердить скрытую заморозку?</div>
          </div>

          <div className="d-flex gap-2">
            <button className="btn btn-success w-50">Подтвердить</button>
            <button
              type="button"
              className="btn btn-secondary text-white w-50"
              onClick={() => reject()}
            >
              Отмена
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
