import React, { useCallback } from 'react';
import { PaidStatus } from '../../common/paid-status.component';
import classNames from 'classnames';
import { IdCell } from '../../common/cards/id-cell-card.component';
import { UserInternalIdCell } from '../../common/cards/id-user-cell-card.component';
import { TariffCell } from '../../common/cards/tariff-cell-card.component';
import { LocalDelivery } from '../../common/cards/local-delivery-cell-card.component';
import { toMoney } from '../../../utils/common.utils';
import { ImagesCell } from '../../common/cards/images-cell-card.component';
import { DeliveryPeriodCell } from '../../common/cards/delivery-date-cell-card.component';
import { WeightVolumeCell } from '../../common/cards/weight-volume-cell-card.component';
import { PriceCell } from '../../common/cards/price-cell-card.component';
import moment from 'moment';
import { TrackNumberCell } from '../../common/cards/track-number-cell-card.component';
import { NameCell } from '../../common/cards/name-cell-card.component';
import { Rbac, useRbac } from '../../common/Rbac';
import {
  CleanDeliveryOrder,
  CleanPurchaseOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { BlockStatus } from '../../common/block-status.component';
import { Manager } from '../../common/cards/manager-cell-card.component';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { useProfile } from '../../../api/profile';
import { SupplierCell } from '../../common/cards/supplier-cell-card.component';
import { ExpandButton, Row, SubRow } from '../../row';

type DeliveryOrdersTabItemProps = {
  data: CleanDeliveryOrder;
  status: CleanDeliveryOrder['status'];
  onCollapsedChange?: () => void;
  collapsed?: boolean;
  defaultCollapsed?: boolean;
};

export function DeliveryOrdersTabItem(props: DeliveryOrdersTabItemProps) {
  const { isLoading, hasPermission } = useRbac();
  const { data: profile } = useProfile();

  const canReadExtendedData =
    !isLoading &&
    hasPermission(RbacObject.DeliveryOrder, 'read:finances-client');

  const ItemPaidStatus = React.useMemo(() => {
    if (!props.data.price)
      return <PaidStatus className="ms-3" type="preliminaryPrice" />;

    if (!!props.data.price && !props.data.paid)
      return <PaidStatus className="ms-3" type="waiting" />;

    if (props.data.paid) return <PaidStatus className="ms-3" type="paid" />;
  }, [props.data.paid, props.data.price]);

  const navigate = useNavigate();

  const href = `/deliveries/delivery-order/${props.data.id}`;

  const handleClick = useCallback(() => {
    navigate(href);
  }, [navigate, href]);

  return (
    <Row
      onClick={handleClick}
      collapsed={props.collapsed}
      defaultCollapsed={props.defaultCollapsed}
      onCollapsedChange={props.onCollapsedChange}
      subRows={props.data.purchaseOrders?.map((purchaseOrder) => (
        <PurchaseOrderSubRow
          key={purchaseOrder.id}
          data={purchaseOrder}
          status={props.status}
        />
      ))}
    >
      {({ collapsed, setCollapsed }) => (
        <>
          {(props.data.unreadMessages ?? 0) > 0 && (
            <div className="position-absolute top-0 start-0">
              <span className="badge bg-green-500">
                {props.data.unreadMessages}
              </span>
            </div>
          )}
          {canReadExtendedData && (
            <Rbac
              object={RbacObject.DeliveryOrder}
              action="read:finances-client"
            >
              <SupplierCell
                supplierName={props.data.supplier?.name ?? ''}
                addHover={true}
              />{' '}
            </Rbac>
          )}
          <div className="col pe-1">
            <Link
              to={href}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IdCell internalId={props.data.internalId} type="delivery" />
            </Link>
          </div>
          <div className="col">
            <UserInternalIdCell user={props.data.user} />
          </div>
          <div className="col">
            <ExpandButton
              onToggle={() => setCollapsed(!collapsed)}
              collapsed={collapsed}
            />
          </div>
          {(props.status === 'assembly' || props.status === 'processing') && (
            <>
              <div className="col-2">
                <TariffCell deliveryMethod={props.data.deliveryMethod!} />
              </div>
              {props.status === 'assembly' && props.data.createdAt ? (
                <div className="col-2 full-text">
                  <div className="color-gray-800 fs-16 fw-500">
                    {moment(props.data.createdAt).format('DD.MM.YYYY')}
                  </div>
                  <div className="color-gray-600 fs-14">
                    <FormattedMessage
                      defaultMessage="Поступил на комплектацию"
                      id="order.status-label.ReceivedForCompletion"
                      description="Статус заказа"
                    />
                  </div>
                </div>
              ) : null}
              {canReadExtendedData && (
                <div className="col-2">
                  <LocalDelivery
                    localDelivery={props.data.localDeliveryMethod}
                    addHover={true}
                  />
                </div>
              )}
              {canReadExtendedData && props.data.user?.manager ? (
                <div className="col-1">
                  <Manager manager={props.data.user?.manager} />
                </div>
              ) : (
                <div className="col-1"></div>
              )}
              {profile && !profile.accesses.includes('fulfillment') && (
                <div className="col-sm-2 col-xl-2 text-end">
                  <div className="">
                    <div className="color-gray-800 fs-16 fw-500  text-truncate">
                      {toMoney(
                        props.data.moderateProductPrice!,
                        props.data.supplier?.purchaseCurrency || 'cny',
                      )}
                    </div>
                    <div className="color-gray-600 fs-14 fw-400">
                      <FormattedMessage
                        defaultMessage="Стоимость товаров"
                        id="delivery.label.priceOfGoods"
                        description="Стоимость товаров"
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {props.status === 'sentToRussia' && (
            <>
              <div
                className={classNames(
                  canReadExtendedData ? 'col-2' : 'col-3',
                  'd-flex',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={props.data.packedFilesV2} />
              </div>
              <div className="col-sm-2 col-xl-3 d-flex align-items-center">
                <DeliveryPeriodCell
                  deliveryMethod={props.data.deliveryMethod!}
                  sentDate={props.data.sentDate!}
                  addHover={true}
                />
              </div>
              {canReadExtendedData && (
                <div className="col-1">
                  <LocalDelivery
                    localDelivery={props.data.localDeliveryMethod}
                    addHover={true}
                  />
                </div>
              )}
              <div
                className={classNames(
                  canReadExtendedData ? 'col-1' : 'col-2 text-end',
                )}
              >
                <WeightVolumeCell
                  weight={props.data.weight}
                  volume={props.data.volume}
                />
              </div>
              {canReadExtendedData && props.data.user?.manager ? (
                <div className="col-1">
                  <Manager manager={props.data.user?.manager} />
                </div>
              ) : (
                <div className="col-1"></div>
              )}
              {canReadExtendedData && (
                <div className="col-1 justify-content-end d-flex align-items-center">
                  <PriceCell
                    price={calculatedPrice(props.data)?.toString()}
                    currency={'usd'}
                  />
                  {ItemPaidStatus}
                </div>
              )}
            </>
          )}
          {['receivedInRussia', 'receivedInRetailRussia'].includes(
            props.status,
          ) && (
            <>
              <div
                className={classNames(
                  canReadExtendedData ? 'col' : 'col-2',
                  'd-flex',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={props.data.packedFilesV2} />
              </div>
              <div
                className={classNames(canReadExtendedData ? 'col-1' : 'col-2')}
              >
                <WeightVolumeCell
                  weight={props.data.weight}
                  volume={props.data.volume}
                />
              </div>
              <div
                className={classNames(
                  'col-2 ps-2',
                  canReadExtendedData ? 'text-start' : '',
                )}
              >
                <div className="color-gray-800 fs-16 fw-500">
                  {moment(props.data.receiveRfDate).format('DD.MM.YYYY')}
                </div>
                <div className="color-gray-600 fs-14">
                  <FormattedMessage
                    defaultMessage="Получено"
                    id="order.status-label.received"
                    description="Статус во вкладке"
                  />
                </div>
              </div>
              <div
                className={classNames(
                  'col-2',
                  canReadExtendedData ? '' : 'text-end',
                )}
              >
                <LocalDelivery
                  localDelivery={props.data.localDeliveryMethod}
                  addHover={true}
                />
              </div>
              {canReadExtendedData && props.data.user?.manager ? (
                <div className="col-1">
                  <Manager manager={props.data.user?.manager} />
                </div>
              ) : (
                <div className="col-1"></div>
              )}
              {canReadExtendedData ? (
                <div className="col-2 justify-content-end d-flex align-items-center">
                  <PriceCell
                    price={calculatedPrice(props.data)?.toString()}
                    currency={'usd'}
                  />
                  {ItemPaidStatus}
                </div>
              ) : null}
            </>
          )}
          {props.status === 'sentToRecipient' && (
            <>
              <div
                className={classNames(
                  canReadExtendedData ? 'col' : 'col-2',
                  'd-flex',
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={props.data.packedFilesV2} />
              </div>
              <div
                className={classNames(
                  canReadExtendedData ? 'col-2 text-start ' : 'col-3',
                )}
              >
                <div className="color-gray-800 fs-16 fw-500">
                  {moment(props.data.receiveRfDate).format('DD.MM.YYYY')}
                </div>
                <div className="color-gray-600 fs-14">
                  <FormattedMessage
                    defaultMessage="Передано в ТК"
                    id="deliveryOrder.status-label.transferredToTheShippingCompany"
                    description="Статус доставки"
                  />
                </div>
              </div>
              <div className="col-2">
                <LocalDelivery
                  localDelivery={props.data.localDeliveryMethod}
                  addHover={true}
                />
              </div>
              <div
                className={classNames(
                  'col-2',
                  canReadExtendedData ? '' : 'text-end',
                )}
              >
                {props.data.trackNumber && (
                  <TrackNumberCell trackNumber={props.data.trackNumber} />
                )}
              </div>
              {canReadExtendedData && props.data.user?.manager ? (
                <div className="col-1">
                  <Manager manager={props.data.user?.manager} />
                </div>
              ) : (
                <div className="col-1"></div>
              )}
              {canReadExtendedData ? (
                <div className="col-1 justify-content-end d-flex align-items-center">
                  {canReadExtendedData && (
                    <>
                      <PriceCell
                        price={calculatedPrice(props.data)?.toString()}
                        currency={'usd'}
                      />
                      {ItemPaidStatus}
                    </>
                  )}
                  {props.data.blocked && (
                    <BlockStatus
                      blocked={props.data.blocked!}
                      className="ms-3"
                      label="Заблокировано в ТК"
                    />
                  )}
                </div>
              ) : null}
            </>
          )}
          {props.status === 'delivered' && (
            <>
              <div
                className="col-5 d-flex"
                onClick={(e) => e.stopPropagation()}
              >
                <ImagesCell imagesList={props.data.packedFilesV2} />
              </div>
              <div className="col-sm-3 col-xl-4 text-end">
                <div className="color-gray-800 fs-16 fw-500">
                  {moment(props.data.deliveredDate).format('DD.MM.YYYY')}
                </div>
                <div className="color-gray-600 fs-14">
                  <FormattedMessage
                    defaultMessage="Получено"
                    id="order.status-label.received"
                    description="Статус во вкладке"
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Row>
  );
}

export const calculatedPrice = (
  data: CleanDeliveryOrder | CleanPurchaseOrder,
) => {
  return data.serviceCharge
    ? Number(data.price) + Number(data.serviceCharge)
    : data.price;
};

type PurchaseOrderSubRowProps = {
  data: CleanPurchaseOrder;
  status: string;
};

export function PurchaseOrderSubRow(props: PurchaseOrderSubRowProps) {
  const navigate = useNavigate();

  const href =
    props.data.type === 'purchase'
      ? `/purchases/${props.data.id}`
      : `/parcels/${props.data.id}`;

  const handleClick = useCallback(() => {
    navigate(href);
  }, [navigate, href]);

  return (
    <SubRow onClick={handleClick}>
      <div className="col pe-1">
        <Link
          to={href}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <IdCell internalId={props.data.internalId} type={props.data.type} />
        </Link>
      </div>
      <div className="col">
        <ImagesCell imagesList={props.data.requestFilesV2} />
      </div>
      <div className="col-8">
        <NameCell name={props.data.name} />
      </div>
      {props.status === 'assembly' && (
        <div className="col-1">
          <NameCell name={props.data.storageLocation!} />
        </div>
      )}
      <div className="col-1 text-end">
        {props.data.count && (
          <FormattedMessage
            defaultMessage="{value} шт"
            id="inputValue.label.pcs"
            description="Единица измерения"
            values={{ value: props.data.count }}
          />
        )}
      </div>
    </SubRow>
  );
}
