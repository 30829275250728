export const IconDefectCheck = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2508_987)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50 8C50 3.58172 53.5817 0 58 0H141C145.418 0 149 3.58172 149 8V12H194C197.314 12 200 14.6863 200 18V194C200 197.314 197.314 200 194 200H5.99999C2.68629 200 0 197.314 0 194V18C0 14.6863 2.68629 12 6 12H50V8ZM58 35C53.5817 35 50 31.4183 50 27H21C17.6863 27 15 29.6863 15 33V179C15 182.314 17.6863 185 21 185H179C182.314 185 185 182.314 185 179V33C185 29.6863 182.314 27 179 27H149C149 31.4183 145.418 35 141 35H58ZM87 57.5C87 50.5964 92.5964 45 99.5 45C106.404 45 112 50.5964 112 57.5V116.5C112 123.404 106.404 129 99.5 129C92.5964 129 87 123.404 87 116.5V57.5ZM100 166C107.732 166 114 159.732 114 152C114 144.268 107.732 138 100 138C92.268 138 86 144.268 86 152C86 159.732 92.268 166 100 166Z"
          fill="#F5D230"
        />
      </g>
      <defs>
        <clipPath id="clip0_2508_987">
          <rect width="200" height="200" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
