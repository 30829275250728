import React from 'react';
import { Header } from '../../common/header.component';
import Dropdown from 'rsuite/Dropdown';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ModalsService } from '../../../services/modals.service';
import { AddStoryModal } from './add-story-modal';
import { mutate as globalMutate } from 'swr';
import { Rbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { AddPromotionModal } from './add-promotion-modal';
import { AddOtcRecommendation } from './AddOtcRecommendation';
import { PromotionReportExportModal } from '../../common/promotion-report-export-modal';
import { useInputState } from '@mantine/hooks';

async function handleDefaultPromotionReportModal() {
  return ModalsService.createModal(PromotionReportExportModal, {
    reportType: 'default',
  });
}

async function handleGeneralPromotionReportModal() {
  return ModalsService.createModal(PromotionReportExportModal, {
    reportType: 'general',
  });
}

async function handleExpandPromotionReportModal() {
  return ModalsService.createModal(PromotionReportExportModal, {
    reportType: 'expand',
  });
}

type AdminMarketingScreen = {
  query: string;
};

export const AdminMarketingScreenContext =
  React.createContext<AdminMarketingScreen | null>(null);

export const AdminMarketingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const paths = location.pathname.split('/');
  const [query, setQuery] = useInputState('');

  function onSelectAdd(key: number | string) {
    switch (key) {
      case 1:
        ModalsService.createModal(AddStoryModal).then(() =>
          globalMutate('/stories/list'),
        );
        break;
      case 'promotion':
        ModalsService.createModal(AddPromotionModal, {}).then(() =>
          globalMutate('/promotion'),
        );
        break;
      case 'OtcRecommendation':
        ModalsService.createModal(AddOtcRecommendation, {}).then(() =>
          globalMutate(() => true),
        );
        break;
      case 'cardPoizon':
        return navigate('/poizon/card');
      case 'brand':
        return navigate('/brands/brand');
      default:
        return;
    }
  }

  return (
    <>
      <Header>
        <div className="d-flex flex-row align-items-center">
          <h1>Маркетинг</h1>
          <Rbac object={RbacObject.Story} action={'write:admin'}>
            <div className="ps-4">
              <Dropdown
                toggleClassName="btn btn-success"
                placement="bottomEnd"
                title="Добавить"
                trigger="click"
                onSelect={onSelectAdd}
              >
                <Rbac object={RbacObject.Story} action={'write:admin'}>
                  <Dropdown.Item eventKey={1}>
                    <i className="bi bi-file-image" />
                    <span className="ps-2">Историю</span>
                  </Dropdown.Item>
                </Rbac>
                <Rbac object={RbacObject.Promotion} action={'write'}>
                  <Dropdown.Item eventKey="promotion">
                    <i className="bi bi-percent" />
                    <span className="ps-2">Акцию</span>
                  </Dropdown.Item>
                </Rbac>
                <Rbac
                  object={RbacObject.OtcRecommendation}
                  action={'write:admin'}
                >
                  <Dropdown.Item eventKey="OtcRecommendation">
                    <i className="bi bi-percent" />
                    <span className="ps-2">Рекомендацию 1688</span>
                  </Dropdown.Item>
                </Rbac>
                <Rbac
                  object={RbacObject.OtcRecommendation}
                  action={'write:admin'}
                >
                  <Dropdown.Item eventKey="cardPoizon">
                    <i className="bi bi-p-square"></i>
                    <span className="ps-2">Товар Poizon</span>
                  </Dropdown.Item>
                </Rbac>
                <Rbac
                  object={RbacObject.OtcRecommendation}
                  action={'write:admin'}
                >
                  <Dropdown.Item eventKey="brand">
                    <i className="bi bi-p-square"></i>
                    <span className="ps-2">Бренд</span>
                  </Dropdown.Item>
                </Rbac>
              </Dropdown>
            </div>
          </Rbac>
          <Rbac object={RbacObject.Promotion} action={'read'}>
            <div className="ps-4">
              <Dropdown
                toggleClassName="btn btn-success"
                placement="bottomEnd"
                title="Отчёты"
                trigger="click"
              >
                <Dropdown.Item
                  onClick={() => handleDefaultPromotionReportModal()}
                >
                  <i className="bi bi-file-excel"></i>
                  <span className="ps-2">Стандартный отчет</span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleGeneralPromotionReportModal()}
                >
                  <i className="bi bi-file-excel"></i>
                  <span className="ps-2">Общие данные </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleExpandPromotionReportModal()}
                >
                  <i className="bi bi-file-excel"></i>
                  <span className="ps-2">Расширенный отчет</span>
                </Dropdown.Item>
              </Dropdown>
            </div>
          </Rbac>
        </div>
      </Header>
      <div className="d-flex flex-column">
        <div className={'d-flex w-full'}>
          <ul className="nav nav-pills mb-4 d-inline-flex align-self-start">
            <li className="nav-item">
              <NavLink
                to="/marketing/stories"
                className="nav-link text-decoration-none"
              >
                Истории
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/marketing/promotions"
                className="nav-link text-decoration-none"
              >
                Акции
              </NavLink>
            </li>
            <Rbac object={RbacObject.OtcRecommendation} action={'read:admin'}>
              <li className="nav-item">
                <NavLink
                  to="/marketing/otcRecommendation"
                  className="nav-link text-decoration-none"
                >
                  Рекомендации 1688
                </NavLink>
              </li>
            </Rbac>
            <Rbac object={RbacObject.OtcRecommendation} action={'read:admin'}>
              <li className="nav-item">
                <NavLink
                  to="/marketing/poizon"
                  className="nav-link text-decoration-none"
                >
                  Товары Poizon
                </NavLink>
              </li>
            </Rbac>
            <Rbac object={RbacObject.OtcRecommendation} action={'read:admin'}>
              <li className="nav-item">
                <NavLink
                  to="/marketing/brands"
                  className="nav-link text-decoration-none"
                >
                  Бренды
                </NavLink>
              </li>
            </Rbac>
          </ul>
          {paths[2] === 'promotions' ? (
            <div className={'my-2 mleft-auto w-30'}>
              <input
                className="form-control"
                type="text"
                value={query}
                placeholder="Поиск по названию/промо/участнику/рефералу"
                onChange={setQuery}
              />
            </div>
          ) : null}
        </div>

        <AdminMarketingScreenContext.Provider value={{ query: query }}>
          <Outlet />
        </AdminMarketingScreenContext.Provider>
      </div>
    </>
  );
};
