import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { trpcSwr } from '../../api/trpc';
import { useDebouncedValue } from '@mantine/hooks';

type AddressLocationSelectProps = {
  name: string;
  placeholder?: string;
  cityName: string;
  value: string | null;
  onSelectPickupPoint: (location?: CdekPickupPoint) => void;
  className?: string;
};

export type CdekPickupPoint = {
  address: string;
  code: string;
};

export const CdekPickupPointSelect = React.forwardRef(function (
  props: AddressLocationSelectProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [debouncedCityName] = useDebouncedValue(props.cityName, 500);
  const { data, mutate } = trpcSwr.cdek.listLocations.useSWR(
    { cityName: debouncedCityName },
    { isDisabled: !debouncedCityName },
  );
  const [selectedValue, setSelectedValue] = useState<CdekPickupPoint | null>(
    null,
  );

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (!data) return [];
      return data.filter(
        (location) =>
          (!inputValue ||
            location.address
              .toLowerCase()
              .includes(inputValue.toLowerCase())) &&
          location.code !== selectedValue?.code,
      );
    },
    [data, selectedValue],
  );

  useEffect(() => {
    if (props.value && data) {
      const location = data.find((location) => location.code === props.value);
      setSelectedValue(location || null);
    } else {
      setSelectedValue(null);
    }
  }, [props.value, data]);

  useEffect(() => {
    props.onSelectPickupPoint(selectedValue ? selectedValue : undefined);
  }, [selectedValue]);

  return (
    <AsyncSelect
      onFocus={() => mutate()}
      name={props.name}
      placeholder={props.value ? '*Загружается*' : props.placeholder}
      loadOptions={loadOptions}
      defaultOptions={data?.filter(
        (location) => location.code !== selectedValue?.code,
      )}
      getOptionLabel={(option) => option.address}
      getOptionValue={(option) => option.code}
      isMulti={false}
      isSearchable={true}
      onChange={(value) => {
        if (value) {
          props.onSelectPickupPoint(value);
        }
      }}
      className={props.className}
      value={selectedValue}
      //@ts-ignore
      ref={ref}
    />
  );
});
