import React from 'react';
import Table, { CellProps } from 'rsuite/Table';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { ModalProps, ModalsService } from '../../../services/modals.service';
import { AlertService } from '../../../services/alert.service';
import { ChangeCommissionModal } from './change-commission-modal';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { api } from '../../../services/api';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { Controller, useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types/form';
import { uploadFile } from '../../../services/file-service';
import { Input } from '../../common/input.component';
import { required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { Currency, Row } from './top-up-accounts';
import { CleanFileDto } from '../../../export-types/cleaned-types';
import { WhisperProps } from 'rsuite/esm/Whisper/Whisper';

const AddSbpAccountModal = ({ close }: ModalProps) => {
  type AddSbpAccountForm = {
    name: string;
    link?: string;
    file: MediaInputValue[];
  };

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<AddSbpAccountForm>();

  const onSave: SubmitHandler<AddSbpAccountForm> = async (form) => {
    const uploaded = await Promise.all(
      form.file.map(async (item) => {
        return item.type === 'new'
          ? uploadFile(item.file).then((data) => data.file)
          : Promise.resolve(item.file);
      }),
    );

    await api
      .post(`/top-up/methods/sbp/accounts`, {
        ...form,
        file: uploaded[0].id,
      })
      .then(close);
  };

  return (
    <div className="modal-dialog">
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить получателя СБП</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <div className="row mt-3">
            <Input
              {...register('name', {
                ...required,
              })}
              className="col"
              type="text"
              title="Наименование"
              error={errors.name?.message}
            />
          </div>
          <div className="row mt-3">
            <Input
              {...register('link')}
              className="col"
              type="text"
              title="Ссылка"
              error={errors.link?.message}
            />
          </div>
          <div className="row mt-3">
            <Controller
              control={control}
              rules={{ ...required }}
              name="file"
              render={({ field, fieldState }) => (
                <InputWrapper
                  theme="light"
                  title="QR код"
                  required
                  error={fieldState.error?.message}
                >
                  <MediaInput {...field} className="mt-2" />
                </InputWrapper>
              )}
            />
          </div>
        </div>
        <div className="modal-footer justify-content-end mt-4">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            Сохранить
          </button>
        </div>
      </form>
    </div>
  );
};
export const SbpMethodActionCell: React.FC<
  CellProps<Row | SbpRow> & { onChange: () => void }
> = ({ rowData, onChange, ...props }) => {
  if (!rowData) return null;
  const speaker: WhisperProps['speaker'] = (
    { onClose, left, top, className },
    ref,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(AddSbpAccountModal)
              .then(AlertService.success)
              .then(onChange);
          }}
        >
          <i className="bi bi-three-dots" />
          <span className="ps-2">Добавить получателя</span>
        </Dropdown.Item>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            ModalsService.createModal(ChangeCommissionModal, {
              method: rowData.method,
              fee: (rowData as Row).fee!,
            })
              .then(AlertService.success)
              .then(onChange);
          }}
        >
          <i className="bi bi-percent" />
          <span className="ps-2">Обновить комиссию</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <Whisper placement="leftStart" trigger="click" speaker={speaker}>
        <IconButton
          appearance="subtle"
          icon={<i className="bi bi-three-dots" />}
        />
      </Whisper>
    </Table.Cell>
  );
};
export const SbpAccountActionCell: React.FC<
  CellProps<SbpAccountRow> & {
    onChange: () => void;
  }
> = ({ rowData, onChange, ...props }) => {
  if (!rowData) return null;
  const speaker: WhisperProps['speaker'] = (
    { onClose, left, top, className },
    ref,
  ) => (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={function () {
            onClose();
            const result = window.confirm(
              `Вы действительно хотите удалить «${rowData.name}»?`,
            );
            if (!result) {
              return;
            }
            api
              .delete(
                `/top-up/methods/${rowData.method}/accounts/${rowData.id}`,
              )
              .then(() => {
                AlertService.success();
              })
              .then(() => {
                onChange();
              });
          }}
        >
          <i className="bi bi-trash" />
          <span className="ps-2">Удалить</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Popover>
  );

  return (
    <Table.Cell
      {...props}
      className="link-group no-padding-cell pt-2"
      align="right"
    >
      <Whisper placement="leftStart" trigger="click" speaker={speaker}>
        <IconButton
          appearance="subtle"
          icon={<i className="bi bi-three-dots" />}
        />
      </Whisper>
    </Table.Cell>
  );
};
export type SbpRow = {
  id: string;
  type: 'method';
  method: 'sbp';
  disabled: boolean;
  currencies: Currency[];
  fee?: number;
  children: Array<SbpAccountRow>;
};
export type SbpAccountRow = {
  type: 'account';
  method: 'sbp';
  id: string;
  uuid: string;
  disabled: boolean;
  currencies: Currency[];
  name: string;
  file: CleanFileDto | null;
};
