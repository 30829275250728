import React from 'react';

type Props = {
  supplierName: string;
  addHover?: boolean;
};

export const SupplierCell: React.FC<Props> = ({ supplierName, addHover }) => {
  return (
    <>
      {(addHover && (
        <div
          className="rs-table-cell-full-text p-0 bg-transparent hover-bg-light"
          style={{
            width: '5vw',
          }}
        >
          <div className="color-gray-800 fs-16 rs-table-cell-content p-0">
            {supplierName}
          </div>
        </div>
      )) || (
        <div>
          <div className="color-gray-800 fs-16">{supplierName}</div>
        </div>
      )}
    </>
  );
};
