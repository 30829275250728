import React, { useContext, useState } from 'react';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import { CleanPromotion } from '../../../export-types/cleaned-types';
import Table, { CellProps } from 'rsuite/Table';
import { TableDateCell } from '../../common/table/table-date-cell.component';
import { ToggleCell } from '../../common/table/toggle-cell';
import { AlertService } from '../../../services/alert.service';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import { ModalsService } from '../../../services/modals.service';
import { AddPromotionModal } from './add-promotion-modal';
import { LinkPromotionModal } from './link-promotion-modal';
import { PROMOTION_TYPES } from '../../../consts/db-values';
import { PromotionPromocodeModal } from './promotion-promocode-modal';
import { usePaginationQs } from '../../../utils/usePagination';
import Pagination from 'rsuite/Pagination';
import MyLoader from '../../common/loader.component';
import classNames from 'classnames';
import { useDebouncedValue } from '@mantine/hooks';
import { AdminMarketingScreenContext } from './admin-marketing-screen';

type Direction = 'asc' | 'desc' | undefined;
type SortColumnName = 'name' | 'startDate' | 'endDate';

export const PromotionsTab = () => {
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(20);
  const [sortColumn, setSortColumn] = useState<{
    column: SortColumnName | null;
    direction: Direction;
  }>({
    column: null,
    direction: undefined,
  });

  const [disabledSortColumn, setDisabledSortColumn] =
    useState<Direction | null>(null);

  const context = useContext(AdminMarketingScreenContext);

  const [debouncedQuery] = useDebouncedValue(context?.query || '', 200);

  const handleSort = (column: SortColumnName) => {
    setSortColumn((prev) => {
      if (prev.column === column) {
        return { column, direction: prev.direction === 'asc' ? 'desc' : 'asc' };
      }
      return { column, direction: 'asc' };
    });
  };

  const handleDisabledSort = () => {
    setDisabledSortColumn((prev) => {
      if (prev === 'desc') {
        return 'asc';
      }
      return 'desc';
    });
  };

  const { data, mutate, isLoading, error } = useSWR<{
    items: CleanPromotion[];
    total: number;
  }>(
    {
      url: '/promotion',
      params: {
        take,
        skip,
        sort: sortColumn.column,
        direction: sortColumn.direction,
        directionDisabled: disabledSortColumn,
        query: debouncedQuery.length >= 3 ? debouncedQuery : undefined,
      },
    },
    fetcher,
  );

  async function checkPromotion(rowData: CleanPromotion, value: boolean) {
    await api.put(`/promotion/${rowData.id}`, {
      disabled: value,
    });
    await mutate();

    AlertService.success();
  }

  const renderMenu =
    (promotion: CleanPromotion): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        onClose();
        if (eventKey === 'edit') {
          ModalsService.createModal(AddPromotionModal, { promotion }).then(() =>
            mutate(),
          );
        } else if (eventKey === 'link') {
          ModalsService.createModal(LinkPromotionModal, { promotion }).then(
            () => mutate(),
          );
        } else if (eventKey === 'linkUrl') {
          navigator.clipboard
            .writeText(`https://my.express-today.ru/?promotion=${promotion.id}`)
            .then(() => {
              AlertService.success();
            });
        } else if (eventKey === 'promocode') {
          ModalsService.createModal(PromotionPromocodeModal, {
            promotion,
          }).then(() => mutate());
        }
      };
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'edit'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'link'}>
              <i className="bi bi-link" />
              <span className="ps-2">Привязать</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'linkUrl'}>
              <i className="bi bi-person-add" />
              <span className="ps-2">Копировать ссылку для клиента</span>
            </Dropdown.Item>
            {!promotion.promocode && (
              <Dropdown.Item eventKey={'promocode'}>
                <i className="bi bi-alphabet" />
                <span className="ps-2">Промокод</span>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Popover>
      );
    };

  const TypeCell = ({ rowData, ...props }: CellProps<CleanPromotion>) =>
    rowData && (
      <Table.Cell {...props}>
        {PROMOTION_TYPES[rowData.type as keyof typeof PROMOTION_TYPES]}
      </Table.Cell>
    );

  const ReferrerInternalIdCell = ({
    rowData,
    ...props
  }: CellProps<CleanPromotion>) =>
    rowData && (
      <Table.Cell {...props}>
        {rowData.referrer
          ? `${rowData.referrer?.internalId} / ${rowData.referrer?.name}`
          : ''}
      </Table.Cell>
    );

  const UsersInternalIdCell = ({
    rowData,
    ...props
  }: CellProps<CleanPromotion>) =>
    rowData && (
      <Table.Cell {...props}>
        {rowData.users?.length ?? 0} / {rowData.referrals?.length ?? 0}
      </Table.Cell>
    );

  const ActionCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanPromotion>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  if ((error || !data) && !isLoading) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  return (
    <div>
      {isLoading ? (
        <MyLoader />
      ) : (
        <>
          {' '}
          <Table
            loading={isLoading}
            data={data?.items}
            headerHeight={57}
            autoHeight
            rowKey="id"
            locale={{
              loading: 'Загрузка',
              emptyMessage: 'Нет акций',
            }}
          >
            <Table.Column flexGrow={1}>
              <Table.HeaderCell fullText>
                <button
                  className="d-flex gap-1 w-100 h-100 bg-transparent"
                  onClick={() => {
                    handleSort('name');
                  }}
                >
                  Внутреннее название
                  <i
                    className={classNames(
                      'bi',
                      sortColumn.column === 'name'
                        ? sortColumn.direction === 'asc'
                          ? 'bi-sort-up'
                          : 'bi-sort-down'
                        : null,
                    )}
                  ></i>
                </button>
              </Table.HeaderCell>
              <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell fullText>
                <button
                  className="d-flex gap-1 w-100 h-100 bg-transparent"
                  onClick={() => {
                    handleSort('startDate');
                  }}
                >
                  Начало действия
                  <i
                    className={classNames(
                      'bi',
                      sortColumn.column === 'startDate'
                        ? sortColumn.direction === 'asc'
                          ? 'bi-sort-up'
                          : 'bi-sort-down'
                        : null,
                    )}
                  ></i>
                </button>
              </Table.HeaderCell>
              <TableDateCell dataKey="startDate" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell fullText>
                <button
                  className="d-flex gap-1 w-100 h-100 bg-transparent"
                  onClick={() => {
                    handleSort('endDate');
                  }}
                >
                  Дата завершения{' '}
                  <i
                    className={classNames(
                      'bi',
                      sortColumn.column === 'endDate'
                        ? sortColumn.direction === 'asc'
                          ? 'bi-sort-up'
                          : 'bi-sort-down'
                        : null,
                    )}
                  ></i>
                </button>
              </Table.HeaderCell>
              <TableDateCell dataKey="endDate" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell fullText>Тип бонусов</Table.HeaderCell>
              <TypeCell />
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={100}>
              <Table.HeaderCell fullText>
                <button
                  className="d-flex gap-1 w-100 h-100 bg-transparent"
                  onClick={() => {
                    handleDisabledSort();
                  }}
                >
                  Включен
                  <i
                    className={classNames(
                      'bi',
                      disabledSortColumn
                        ? disabledSortColumn === 'asc'
                          ? 'bi-sort-up'
                          : 'bi-sort-down'
                        : null,
                    )}
                  ></i>
                </button>
              </Table.HeaderCell>
              <ToggleCell
                dataKey="disabled"
                inverted={true}
                onChange={checkPromotion}
              />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell fullText>Промокод</Table.HeaderCell>
              <Table.Cell dataKey="promocode" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell fullText>Рефер</Table.HeaderCell>
              <ReferrerInternalIdCell />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell fullText>Участники/рефералы</Table.HeaderCell>
              <UsersInternalIdCell />
            </Table.Column>
            <Table.Column>
              <Table.HeaderCell> </Table.HeaderCell>
              <ActionCell />
            </Table.Column>
          </Table>
          {data && data.total > take && (
            <Pagination
              className="mt-2 pb-4"
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="md"
              layout={['pager']}
              total={data.total}
              limit={take}
              activePage={page}
              onChangePage={handleSetPage}
              onChangeLimit={handleChangeLimit}
            />
          )}
        </>
      )}
    </div>
  );
};
