import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { InputWrapper } from '../../common/input-wrapper';
import { required } from '../../../utils/validations';
import { useLocalDeliveryMethods } from '../../../api/delivery';
import {
  CdekPickupPoint,
  CdekPickupPointSelect,
} from '../../common/cdek-pickup-point-select';

type Form = {
  localDelivery: string;
  type: 'legal' | 'individual' | 'pickupPoint';
  name: string;
  phone: string;
  city: string;
  passport?: string;
  companyInn?: string;
  companyName?: string;
  pickupPoint?: string;
  cdekCode?: string;
  isCdek?: boolean;
};
type RequestAddressEditFormProps = {
  id: string;
  defaultValues: Form;
  onSave: () => void;
  onCancel: () => void;
  supplierId?: number;
};

export const RequestAddressEditForm: React.FC<RequestAddressEditFormProps> = (
  props,
) => {
  const { control, formState, handleSubmit, watch, setValue } = useForm<Form>({
    defaultValues: props.defaultValues,
  });

  const [type, city, isCdek, localDelivery] = watch([
    'type',
    'city',
    'isCdek',
    'localDelivery',
  ]);

  const { data: localDeliveryMethodsData } = useLocalDeliveryMethods(
    true,
    props.supplierId,
    props.defaultValues.localDelivery,
  );

  const onSubmit = handleSubmit(async (form) => {
    api
      .put(`/delivery-order/${props.id}/address`, {
        localDelivery: form.localDelivery,
        type: form.type,
        name: form.name,
        phone: form.phone,
        city: form.city,
        passport: form.type === 'individual' ? form.passport : null,
        companyInn: form.type === 'legal' ? form.companyInn : null,
        companyName: form.type === 'legal' ? form.companyName : null,
        pickupPoint: form.type === 'pickupPoint' ? form.pickupPoint : null,
        ...(form.isCdek ? { cdekCode: form.cdekCode } : null),
      })
      .then(() => {
        AlertService.success();
        props.onSave();
      });
  });

  useEffect(() => {
    const deliveryMethod = localDeliveryMethodsData?.find(
      (method) => method.id === localDelivery,
    );
    if (deliveryMethod) {
      setValue('isCdek', deliveryMethod?.isCdek);
    }
  }, [localDelivery]);

  function handleCancel() {
    props.onCancel();
  }

  const onSelectPickupPoint = (pickupPoint?: CdekPickupPoint) => {
    setValue('cdekCode', pickupPoint?.code);
  };
  return (
    <form onSubmit={onSubmit}>
      <Controller
        control={control}
        name="localDelivery"
        rules={{
          ...required,
        }}
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Доставка по РФ"
            error={fieldState.error?.message}
          >
            <select {...field} className="form-select">
              {localDeliveryMethodsData?.map((localDelivery) => (
                <option key={localDelivery.id} value={localDelivery.id}>
                  {localDelivery.name}
                </option>
              ))}
            </select>
          </InputWrapper>
        )}
      />
      <Controller
        control={control}
        name="city"
        rules={{
          ...required,
        }}
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Город"
            error={fieldState.error?.message}
          >
            <input
              type="text"
              placeholder="Город"
              className="form-control"
              {...field}
            />
          </InputWrapper>
        )}
      />
      {isCdek && (
        <Controller
          name="cdekCode"
          control={control}
          rules={{
            ...required,
          }}
          render={({ field, fieldState }) => (
            <InputWrapper
              theme="light"
              className="mt-3"
              title={'Адрес ПВЗ'}
              error={fieldState.error?.message}
            >
              <CdekPickupPointSelect
                {...field}
                value={field.value || ''}
                placeholder={'Укажите адрес до какого пункта выдачи доставить'}
                cityName={city}
                onSelectPickupPoint={onSelectPickupPoint}
              />
            </InputWrapper>
          )}
        />
      )}
      <Controller
        control={control}
        name="type"
        rules={{
          ...required,
        }}
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Тип"
            error={fieldState.error?.message}
          >
            <select {...field} className="form-select">
              <option value={'individual'}>Физ. лицо</option>
              <option value={'legal'}>Юридическое лицо или ИП</option>
              <option value={'pickupPoint'}>ПВЗ</option>
            </select>
          </InputWrapper>
        )}
      />
      {type === 'legal' && (
        <Controller
          control={control}
          name="companyName"
          render={({ field, fieldState }) => (
            <InputWrapper
              theme="light"
              className="mt-3"
              title="Имя компании"
              error={fieldState.error?.message}
            >
              <input
                type="text"
                placeholder="Имя компании"
                className="form-control"
                {...field}
              />
            </InputWrapper>
          )}
        />
      )}
      {type === 'pickupPoint' && (
        <Controller
          control={control}
          name="pickupPoint"
          render={({ field, fieldState }) => (
            <InputWrapper
              theme="light"
              className="mt-3"
              title="ПВЗ"
              error={fieldState.error?.message}
            >
              <input
                type="text"
                placeholder="Пункт выдачи заказов"
                className="form-control"
                {...field}
              />
            </InputWrapper>
          )}
        />
      )}
      {type === 'legal' && (
        <Controller
          control={control}
          name="companyInn"
          render={({ field, fieldState }) => (
            <InputWrapper
              theme="light"
              className="mt-3"
              title="ИНН"
              error={fieldState.error?.message}
            >
              <input
                type="text"
                placeholder="ИНН"
                className="form-control"
                {...field}
              />
            </InputWrapper>
          )}
        />
      )}
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="ФИО"
            error={fieldState.error?.message}
          >
            <input
              type="text"
              placeholder="ФИО получателя"
              className="form-control"
              {...field}
            />
          </InputWrapper>
        )}
      />
      {type === 'individual' && (
        <Controller
          control={control}
          name="passport"
          render={({ field, fieldState }) => (
            <InputWrapper
              theme="light"
              className="mt-3"
              title="Паспорт"
              error={fieldState.error?.message}
            >
              <input
                type="text"
                placeholder="Серия и номер паспорта"
                className="form-control"
                {...field}
              />
            </InputWrapper>
          )}
        />
      )}
      <Controller
        control={control}
        name="phone"
        rules={{
          ...required,
        }}
        render={({ field, fieldState }) => (
          <InputWrapper
            theme="light"
            className="mt-3"
            title="Телефон"
            error={fieldState.error?.message}
          >
            <input
              type="text"
              placeholder="Телефон"
              className="form-control"
              {...field}
            />
          </InputWrapper>
        )}
      />
      <div className="mt-2">
        <button
          className="btn btn-link text-decoration-none"
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Сохранить'
          )}
        </button>
        <button
          className="btn btn-link text-decoration-none"
          type="button"
          disabled={formState.isSubmitting}
          onClick={handleCancel}
        >
          Отменить
        </button>
      </div>
    </form>
  );
};
