import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { Params } from '../../../api/use-purchase-orders';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type TotalParameters = {
  totalWeight?: number;
  totalVolume?: number;
  totalPlaces?: string;
};
export function PurchaseOrdersTotalParameters(params: Params) {
  const { data } = useSWR<{
    totalParameters: TotalParameters;
  }>({ url: '/purchase-order/totalParameters', params: params }, fetcher);

  if (!data) {
    return null;
  }
  return (
    <div className="ms-4 mt-2">
      <FormattedMessage
        id="purchaseOrders.label.totalParametrs"
        defaultMessage="Мест {places}; {volume}м³; {weight}кг"
        description="Информация о заказах"
        values={{
          places: data.totalParameters.totalPlaces,
          volume: data.totalParameters.totalVolume,
          weight: new Intl.NumberFormat('ru-RU').format(
            data.totalParameters.totalWeight ?? 0,
          ),
        }}
      />
    </div>
  );
}
